import { FC, HTMLAttributes } from "react";
import styled from "styled-components/macro";
import { theme } from "../../constants/theme.config";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 60%;
  max-width: 65rem;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 3rem 0;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    width: 70%;
  }
  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 100%;

    padding: 2rem 1rem;
    margin-top: 0;
  }
`;

const Container: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <Wrapper {...props}>{children}</Wrapper>
  );
};

export default Container;
