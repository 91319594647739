import React from "react";

export default function Download(): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="10" fill="#4A89CA" />
      <path
        d="M19.2804 19.663C19.2804 19.663 22.8506 19.6946 23.1882 19.6976C25.0631 19.7143 26.5967 18.2085 26.6145 16.334C26.6322 14.4596 25.1273 12.9264 23.2524 12.9098C23.2438 12.9097 23.2361 12.9109 23.2275 12.9109C23.2558 12.6989 23.2758 12.4843 23.2779 12.2653C23.3051 9.38309 20.991 7.02575 18.1085 7.00021C15.8625 6.98031 13.9405 8.38472 13.1856 10.3678C12.723 9.89588 12.0798 9.60023 11.3668 9.59391C9.94118 9.58127 8.77483 10.7265 8.76135 12.1517C8.761 12.1881 8.76496 12.2234 8.76591 12.2595C7.18756 12.6807 6.01638 14.1089 6.00017 15.8215C5.98072 17.8781 7.63195 19.5599 9.68897 19.5781C10.0442 19.5812 13.2669 19.6097 13.2669 19.6097"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8181 23.8345L16.6215 27.0311L13.4248 23.8345"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.6211 16.3756V24.9"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
