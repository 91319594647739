import React, { CSSProperties, ReactNode } from "react";
import SkeletonComponent, {
  SkeletonTheme as DefaultSkeletonTheme,
} from "react-loading-skeleton";
import styled from "styled-components";

interface SkeletonProps {
  count?: number;
  duration?: number;
  delay?: number;
  width?: string | number;
  wrapper?: ReactNode;
  height?: string | number;
  circle?: boolean;
  style?: CSSProperties;
  className?: string;

  color?: string;
}

const SkeletonContainer = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.components.input.borderRadius};

  & span {
    padding-left: 0 !important;
  }
`;

const SkeletonTheme = styled(DefaultSkeletonTheme).attrs((props) => ({
  color: props.color || props.theme.globalStyling.background,
}))``;

export default function Skeleton(props: SkeletonProps): React.ReactElement {
  return (
    <SkeletonContainer>
      <SkeletonTheme color={props.color}>
        <SkeletonComponent {...props} />
      </SkeletonTheme>
    </SkeletonContainer>
  );
}
