export const DB_STORE_NAME = "SensorData";

export const DBConfig = {
  name: "SmartSensorDatabase",
  version: 1,
  objectStoresMeta: [
    {
      store: DB_STORE_NAME,
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "data", keypath: "data", options: { unique: false } },
        { name: "timestamp", keypath: "timestamp", options: { unique: false } }
      ]
    }
  ]
};
