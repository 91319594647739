import { SingleValue } from "react-select";
import { OptionType } from "./types";

interface IDataTypes {
  [key: string]: (number | string)[];
}

export const dataTypes: IDataTypes = {
  TEMP: ["temperature", 0],
  VOC: ["voc", 1],
  HUMID: ["humid", 2],
  ILLUMINANCE: ["illuminance", 3],
  MIC: ["microphone", 4],
  ISR: ["button", 5],
  LED: ["led-ring", 6],
  MOTION: ["motion", 7],
  CO2: ["co2", 8],
  "SENSORS-CONFIG": ["sensors-config", 10],
};

interface IPayloadKeyTypes {
  [key: string]: string[];
}

export const payloadKeys: IPayloadKeyTypes = {
  TEMP: ["temperature"],
  VOC: ["voc"],
  HUMID: ["humidity"],
  ILLUMINANCE: ["illuminance"],
  MIC: ["noise-level"],
  MOTION: ["occupancy"],
  CO2: ["co2"],
};

export const SensorKey = {
  TEMPERATURE: "temperature",
  VOC: "voc",
  HUMIDITY: "humid",
  ILLUMINANCE: "illuminance",
  MICROPHONE: "microphone",
  MOTION: "occupancy",
  CO2: "co2",
  IBEACON: "ibeacon",
} as const;

export type SensorKeyType = typeof SensorKey[keyof typeof SensorKey];

export const SensorName: { [sensor in SensorKeyType]: string } = {
  [SensorKey.TEMPERATURE]: "Temperature",
  [SensorKey.VOC]: "VOC",
  [SensorKey.HUMIDITY]: "Humidity",
  [SensorKey.ILLUMINANCE]: "Illuminance",
  [SensorKey.MICROPHONE]: "Noise",
  [SensorKey.MOTION]: "Occupancy",
  [SensorKey.CO2]: "CO2",
  [SensorKey.IBEACON]: "iBeacon",
};

export const ParameterKeyNumber = {
  COV: "cov",
  DEAD_TIME: "dead-time",
  REPORT_TIME: "report-time",
  EMISSIVITY: "emissivity",
  NTC_OFFSET: "ntc-offset",
  HOLD_TIME: "hold-time",
  SLOPE: "slope",
  INTERCEPT: "intercept",
} as const;

export const ParameterKeyOption = {
  SOURCE: "source",
  CEILING_TYPE: "ceiling-type",
  SENSITIVITY: "sensitivity",
  MODE: "mode",
  TX_POWER: "tx-power",
} as const;

export type ParameterNumberType = typeof ParameterKeyNumber[keyof typeof ParameterKeyNumber];
export type ParameterOptionType = typeof ParameterKeyOption[keyof typeof ParameterKeyOption];

export type ParameterType = ParameterNumberType | ParameterOptionType;

export const ParameterKeyName: { [key in ParameterType]: string } = {
  [ParameterKeyNumber.COV]: "COV",
  [ParameterKeyNumber.DEAD_TIME]: "Dead time",
  [ParameterKeyNumber.REPORT_TIME]: "Report time",
  [ParameterKeyNumber.EMISSIVITY]: "Emissivity",
  [ParameterKeyNumber.NTC_OFFSET]: "NTC offset",
  [ParameterKeyNumber.SLOPE]: "Slope",
  [ParameterKeyNumber.INTERCEPT]: "Intercept",
  [ParameterKeyNumber.HOLD_TIME]: "Hold time",
  [ParameterKeyOption.SOURCE]: "Source",
  [ParameterKeyOption.CEILING_TYPE]: "Ceiling type",
  [ParameterKeyOption.SENSITIVITY]: "Sensitivity",
  [ParameterKeyOption.MODE]: "Mode",
  [ParameterKeyOption.TX_POWER]: "TX power",
};

export const DropDownOptions = {
  [ParameterKeyOption.SOURCE]: [
    { value: 0, label: "FIR" },
    { value: 1, label: "NTC" },
    { value: 2, label: "Fusion" },
  ],
  [ParameterKeyOption.SENSITIVITY]: [
    { value: 0, label: "LOW" },
    { value: 1, label: "MEDIUM" },
    { value: 2, label: "HIGH" },
  ],
  [ParameterKeyOption.CEILING_TYPE]: [
    { value: 0, label: "OTHER" },
    { value: 1, label: "PLASTER" },
    { value: 2, label: "METAL" },
    { value: 3, label: "CONCRETE" },
  ],
  [ParameterKeyOption.MODE]: [
    { value: 0, label: "PIR" },
    { value: 1, label: "Sound" },
    { value: 2, label: "Fusion" },
  ],
  [ParameterKeyOption.TX_POWER]: [
    { value: 0, label: "-12 dBm" },
    { value: 1, label: "-9 dBm" },
    { value: 2, label: "-6 dBm" },
    { value: 3, label: "-3  dBm" },
    { value: 4, label: "0 dBm" },
    { value: 5, label: "+3 dBm" },
    { value: 6, label: "+6 dBm" },
    { value: 7, label: "+9 dBm" },
  ],
};

export type SENSOR_CONFIGURATION = {
  [ParameterKeyNumber.COV]?: number;
  [ParameterKeyNumber.DEAD_TIME]?: number;
  [ParameterKeyNumber.REPORT_TIME]?: number;
  [ParameterKeyNumber.EMISSIVITY]?: number;
  [ParameterKeyNumber.NTC_OFFSET]?: number;
  [ParameterKeyNumber.SLOPE]?: number;
  [ParameterKeyNumber.INTERCEPT]?: number;
  [ParameterKeyNumber.HOLD_TIME]?: number;
  [ParameterKeyOption.SOURCE]?: SingleValue<OptionType>;
  [ParameterKeyOption.CEILING_TYPE]?: SingleValue<OptionType>;
  [ParameterKeyOption.SENSITIVITY]?: SingleValue<OptionType>;
  [ParameterKeyOption.MODE]?: SingleValue<OptionType>;
  [ParameterKeyOption.TX_POWER]?: SingleValue<OptionType>;
};

export type NodeFormData = {
  [SensorKey in SensorKeyType]: SENSOR_CONFIGURATION;
};

export interface Range {
  MINIMUM?: number;
  MAXIMUM?: number;
}

export interface SensorLimits {
  [ParameterKeyNumber.COV]?: Range;
  [ParameterKeyNumber.DEAD_TIME]?: Range;
  [ParameterKeyNumber.REPORT_TIME]?: Range;
  [ParameterKeyNumber.EMISSIVITY]?: Range;
  [ParameterKeyNumber.NTC_OFFSET]?: Range;
  [ParameterKeyNumber.SLOPE]?: Range;
  [ParameterKeyNumber.INTERCEPT]?: Range;
  [ParameterKeyNumber.HOLD_TIME]?: Range;
}

export const CONFIGURATION_LIMITS: { [key in SensorKeyType]: SensorLimits } = {
  [SensorKey.TEMPERATURE]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 0.1,
      MAXIMUM: 5,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 30,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
    [ParameterKeyNumber.EMISSIVITY]: {
      MINIMUM: 0,
      MAXIMUM: 100,
    },
    [ParameterKeyNumber.NTC_OFFSET]: {
      MINIMUM: 0,
      MAXIMUM: 1,
    },
  },
  [SensorKey.ILLUMINANCE]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 5,
      MAXIMUM: 1000,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
    [ParameterKeyNumber.SLOPE]: {
      MINIMUM: 1,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.INTERCEPT]: {
      MINIMUM: undefined,
      MAXIMUM: undefined,
    },
  },
  [SensorKey.MICROPHONE]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 5,
      MAXIMUM: 30,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 60,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
  },
  [SensorKey.HUMIDITY]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 1,
      MAXIMUM: 50,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 30,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
  },
  [SensorKey.VOC]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 1,
      MAXIMUM: 50,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 30,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
  },
  [SensorKey.CO2]: {
    [ParameterKeyNumber.COV]: {
      MINIMUM: 5,
      MAXIMUM: 200,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 60,
      MAXIMUM: 86400,
    },
    [ParameterKeyNumber.DEAD_TIME]: {
      MINIMUM: 1,
      MAXIMUM: 28800,
    },
  },
  [SensorKey.MOTION]: {
    [ParameterKeyNumber.HOLD_TIME]: {
      MINIMUM: 0,
      MAXIMUM: 65535,
    },
    [ParameterKeyNumber.REPORT_TIME]: {
      MINIMUM: 60,
      MAXIMUM: 86400,
    },
  },
  [SensorKey.IBEACON]: {},
};
