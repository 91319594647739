interface IStyling {
    color?: string;
    background?: string;
    hover?: string;
    backgroundHover?: string;
  
    boxShadow?: string;
  
    opacity?: string;
  
    borderRadius?: string;
    borderColor?: string;
  
    fontWeight?: string | number;
  }
  
  interface IComponents {
    button: {
      primary: IStyling;
      secondary: IStyling;
      link: IStyling;
    };
    anchor: IStyling;
    card: IStyling;
    highlight: IStyling;
    input: IStyling;
    navCard: IStyling;
  }
  
  interface IPalette {
    primary: string;
    secondary: string;
  
    lightgray: string;
    success: string;
    danger: string;
    info: string;
    warning: string;
  
    header: string;
  
    black: string;
    lightblack: string;
    silver: string;
    gray: string;
    white: string;
    whitesnow: string;
    darkgray: string;
  }
  
  interface IGlobalStyling {
    color: string;
    background: string;
  
    fontFamily: string;
    fontSize: string;
  }
  
  interface MediaQueries {
    mobile: string;
    tablet: string;
    desktop: string;
  }
  
  interface IMediaQueriesPixels {
    mobile: number;
    tablet: number;
    desktop: number;
  }
  
  interface IFonts {
    heading: string;
    text: string;
  }
  
export const mediaQueries: IMediaQueriesPixels = {
  mobile: 768,
  tablet: 1024,
  desktop: 1366,
};
  
export interface IDefaultTheme {
    mediaQueries: MediaQueries;
    globalStyling: IGlobalStyling;
  
    palette: IPalette;
  
    components: IComponents;
  
    fonts: IFonts;
  }
  
const colors: IPalette = {
  primary: "#252361",
  secondary: "black",
  
  success: "#28a745",
  danger: "#dc3545",
  info: "#17a2b8",
  warning: "#ffc107",
  
  header: "#618dca",
  
  black: "#000",
  lightblack: "#333",
  gray: "#8E8E8E",
  silver: "#BABABA",
  white: "#fff",
  whitesnow: "#e5e5e5",
  lightgray: "#C4C4C4",
  darkgray: "#3E4246"
};
  
export const theme: IDefaultTheme = {
  mediaQueries: {
    mobile: `${mediaQueries.mobile}px`,
    tablet: `${mediaQueries.tablet}px`,
    desktop: `${mediaQueries.desktop}px`,
  },
  
  palette: colors,
  
  globalStyling: {
    color: colors.black,
    background: colors.white,
  
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
  },
  
  fonts: {
    heading: "Open sans",
    text: "Open sans",
  },
  
  components: {
    button: {
      primary: {
        color: colors.white,
        background: colors.primary,
        backgroundHover: "#343187",
        boxShadow: "none",
        borderRadius: "0.3rem",
        fontWeight: "bold",
      },
      secondary: {
        color: colors.white,
        background: colors.secondary,
        backgroundHover: colors.lightblack,
        boxShadow: "1px 2px 8px gray",
        borderRadius: "0.3rem",
        fontWeight: "bold",
      },
      link: {
        background: "transparent",
        color: colors.gray,
        fontWeight: "bold",
        hover: colors.black,
      },
    },
    anchor: {
      color: colors.black,
      hover: "#333",
      fontWeight: "bold",
    },
    card: {
      color: colors.black,
      background: colors.white,
      boxShadow: "1px 1px 10px #80808055",
      borderColor: colors.primary,
      borderRadius: "0.9rem",
    },
    highlight: {
      color: colors.silver,
      background: colors.lightgray,
      hover: colors.whitesnow,
      borderRadius: "0.3rem",
    },
    input: {
      background: "#dcdcdc4f",
      borderRadius: "0.3rem",
    },
    navCard: {
      background: colors.white,
      boxShadow: `1px 1px 3px ${colors.gray}`,
      borderRadius: "0.7rem",
    },
  },
};
  
export const LightTheme: IDefaultTheme = {
  ...theme,
  globalStyling: {
    ...theme.globalStyling,
    background: "#eaeaea",
  },
  palette: {
    ...theme.palette,
    danger: "#e66960",
    success: "#6bc28f",
  },
};
  
export const DarkTheme: IDefaultTheme = {
  ...theme,
  globalStyling: {
    ...theme.globalStyling,
  },
};
  
export const colorPallete = [
  "#5672ff", "#fd7272", "#2ebd3e", "#9b59b6", "#34495e",
  "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50",
  "#f1c40f", "#e67e22", "#e74c3c", "#ecf0f1", "#95a5a6",
  "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d",
  "#55efc4", "#81ecec", "#74b9ff", "#a29bfe", "#dfe6e9",
  "#00b894", "#00cec9", "#0984e3", "#6c5ce7", "#ffeaa7",
  "#fab1a0", "#ff7675", "#fd79a8", "#fdcb6e", "#e17055",
  "#d63031", "#feca57", "#5f27cd", "#54a0ff", "#01a3a4"
];
