import React, { ButtonHTMLAttributes, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import reactifyWebComponent from "reactify-wc";
import Button from "../components/shared-components/Button";
import FileInputDragDrop from "../components/FileInputDragDrop";
import JSZip from "jszip";
import { LATEST_PRERELEASE, MANIFEST_FILE_NAME, urlOptions, URL_TYPES, LATEST_RELEASE } from "../constants/constants";
import Toast from "../components/Toast";
import FileItem from "../components/FileItem";
import { debounce } from "../utils/debounce";
import { Select } from "../components/Select";
import { OptionType } from "../constants/types";
import { SingleValue } from "react-select";
import { Tabs, Tab, TabList, TabPanel } from "../components/Tabs";
import useQuery from "../hooks/useQuery";

interface IFlashButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  manifest: string;
}

const ESPWebInstallButton = reactifyWebComponent(
  "esp-web-install-button"
) as React.ForwardRefExoticComponent<IFlashButton>;

const Container = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  width: 30%;
  margin: 0 auto;
  margin-top: 10rem;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 1.5rem;

  --esp-tools-success-color: ${(p) => p.theme.palette.success};
  --esp-tools-error-color: ${(p) => p.theme.palette.danger};
  --esp-tools-progress-color: ${(p) => p.theme.palette.info};

  & h2 {
    text-align: center;
    margin-top: 1rem;
  }

  & h4 {
    margin: 0.5rem 1rem;
    opacity: 0.75;
    color: ${(p) => p.theme.palette.gray};
  }

  & p {
    margin: 0 1rem;
    opacity: 0.75;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 0.4px;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 70%;
    padding: 1rem 2rem 2rem 2rem;
  }
`;

const FileContainer = styled.div`
  height: 10vh;
  padding: 1rem 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & input[type="file"] {
    display: none;
  }
`;

const FileSelectLink = styled.span`
  color: ${(p) => p.theme.palette.black};
  cursor: pointer;
`;

const ErrorText = styled.span`
  color: ${(p) => p.theme.palette.danger};
`;

const SuccessText = styled.span`
  color: ${(p) => p.theme.palette.success};
`;

interface PartsData {
  path: string | null;
  offset: number;
}

interface BuildsData {
  chipFamily: string;
  improv: boolean;
  parts: PartsData[];
}

interface FormData {
  name: string;
  version: string;
  new_install_prompt_erase: boolean;
  builds: BuildsData[];
}

const initalFormState: FormData = {
  name: "Smart Sensor Firmware",
  version: "",
  new_install_prompt_erase: true,
  builds: [
    {
      chipFamily: "ESP32",
      improv: false,
      parts: [
        { path: "bootloader.bin", offset: 0x1000 },
        { path: "partition-table.bin", offset: 0x8000 },
        { path: "initial_ota_data.bin", offset: 0xd000 },
        { path: "smart_sensors.bin", offset: 0x10000 },
      ],
    },
  ],
};

enum TABS_INDEX {
  URL,
  ZIP,
}

const FIRMWARE_URL_KEY = "firmware-url";

const Flash = (): React.ReactElement => {
  const [urls, setUrls] = useState(urlOptions);
  const [form, setForm] = useState(initalFormState);
  const [manifestURL, setManifestURL] = useState("");
  const [flashDisabled, setFlashDisabled] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const refManifestFile = useRef<HTMLInputElement>(null);
  const [firmwareURL, setFirmwareURL] = useState<SingleValue<OptionType>>(urlOptions[0]);
  const [errorText, setErrorText] = useState("");
  const query = useQuery();

  const handleError = (errorText: string) => {
    setFlashDisabled(true);
    setErrorText(errorText);
    Toast({ type: "warning", text: errorText });
  };

  const handleManifestURL = (form: FormData) => {
    const fr = new FileReader();
    fr.onload = () => {
      setManifestURL(fr.result as string);
    };
    const formFile = new Blob([JSON.stringify(form, null, 2)]);
    fr.readAsDataURL(formFile);

    setFlashDisabled(false);
  };

  const readManifestFromZip = useCallback((file: File | ArrayBuffer) => {
    const zip = new JSZip();

    zip
      .loadAsync(file, {})
      .then(async function (zip) {
        const manifest = zip.file(MANIFEST_FILE_NAME);
        if (manifest) {
          const stream = await manifest.async("string");
          const json = JSON.parse(stream) as FormData;
          setForm(json);
          handleManifestURL(json);
          setErrorText("");
          Toast({ type: "success", text: "Found the JSON file" });
        } else {
          handleError("JSON file not found");
        }
      })
      .catch(() => {
        handleError("Invalid zip file");
      });
  }, []);

  const handleClickSelectFile = () => refManifestFile.current?.click();

  const handleSelectFile = (e: FormEvent<HTMLInputElement>) => {
    e.currentTarget.files && handleFile(e.currentTarget.files[0]);
  };

  const handleFile = async (file: File) => {
    if (!file) return;
    setFile(file);
    readManifestFromZip(file);
  };

  const fetchAndReadZip = useCallback(
    async (url: string) => {
      setFile(null);
      try {
        const res = await fetch(url);
        const buff = await res.arrayBuffer();
        readManifestFromZip(buff);
      } catch (error) {
        handleError("Failed to fetch");
      }
    },
    [readManifestFromZip]
  );

  const debounceFetchAndReadZip = React.useMemo(() => debounce(fetchAndReadZip, 500), [fetchAndReadZip]);

  const createSelectOption = useCallback((inputValue: string) => {
    const newOption: OptionType = {
      value: URL_TYPES["CUSTOM RELEASE"],
      label: inputValue,
    };
    setUrls((prev) => {
      if (prev.find((el) => el.value === URL_TYPES["CUSTOM RELEASE"])) prev.pop();
      prev.push(newOption);
      return prev;
    });
    setFirmwareURL(newOption);
  }, []);

  const onChangeSelect = (selected: SingleValue<OptionType>) => {
    setFirmwareURL(selected);
    debounceFetchAndReadZip(selected?.label);
  };

  const removeFile = () => {
    setFile(null);
    setForm(initalFormState);
  };

  // reset form
  const onSelectTab = (index: number, last: number, event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    setErrorText("");

    if (index === TABS_INDEX.URL) {
      setFile(null);
      setFirmwareURL(urlOptions[0]);
      fetchAndReadZip(urlOptions[0].label);
    }
    if (index === TABS_INDEX.ZIP) {
      setForm(initalFormState);
      setFlashDisabled(true);
    }
  };

  const formatSelectOption = (option: OptionType) =>
    `${URL_TYPES[option.value] || URL_TYPES[URL_TYPES["CUSTOM RELEASE"]]}`;

  useEffect(() => {
    const queryURL = query.get(FIRMWARE_URL_KEY);
    if (queryURL) {
      createSelectOption(queryURL);
      debounceFetchAndReadZip(queryURL);
    } else {
      debounceFetchAndReadZip(LATEST_RELEASE);
    }
  }, [debounceFetchAndReadZip, createSelectOption, query]);

  return (
    <Container>
      <h2>Sauter Flash Tool</h2>
      <Tabs onSelect={onSelectTab}>
        <TabList>
          <Tab>URL</Tab>
          <Tab>ZIP</Tab>
        </TabList>

        <TabPanel>
          <Select
            createable={true}
            label="Choose or <input> firmware URL"
            defaultValue={firmwareURL}
            value={firmwareURL}
            options={urls}
            onChange={onChangeSelect}
            formatOptionLabel={formatSelectOption}
            onCreateOption={createSelectOption}
          />
        </TabPanel>
        <TabPanel>
          <FileInputDragDrop onDropItem={handleFile}>
            <FileContainer onClick={handleClickSelectFile}>
              <input type="file" id="manifest-file" ref={refManifestFile} onChange={handleSelectFile} value="" />
              {file ? (
                <FileItem file={file} onRemoveItem={removeFile} />
              ) : (
                <h4>
                  <FileSelectLink>Choose a zip file</FileSelectLink> or drop it here.
                </h4>
              )}
            </FileContainer>
          </FileInputDragDrop>
        </TabPanel>
      </Tabs>

      <p>Status: {errorText ? <ErrorText>{errorText}</ErrorText> : <SuccessText>{form.version}</SuccessText>}</p>
      <p>
        <ErrorText>{firmwareURL?.description}</ErrorText>
      </p>
      <br />
      <p>
        {"To flash the device we recommend using an "}
        <a href="https://ftdichip.com/products/ttl-232r-3v3/" target="_blank" rel="noreferrer">
          FTDI cable
        </a>
        .
      </p>

      <ESPWebInstallButton manifest={manifestURL}>
        <Button label="Flash device" color="primary" slot="activate" disabled={flashDisabled} />
        <p slot="unsupported">Your browser doesn't support WebSerial! Try Google Chrome, Microsoft Edge or Opera!</p>
        <p slot="not-allowed">You are not allowed to use this on HTTP!</p>
      </ESPWebInstallButton>
    </Container>
  );
};

export default Flash;
