import { useMqttState } from "mqtt-react-hooks";
import React, { FormEvent, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { ActionMeta, SingleValue } from "react-select";
import styled from "styled-components";
import Button from "../components/shared-components/Button";
import { animationOptions } from "../constants/ledAnimations";
import { OptionType } from "../constants/types";
import { useSensorData } from "../context/SensorDataContext";
import { Input } from "../components/shared-components/Input";
import { useForm } from "react-hook-form";
import { Select } from "../components/Select";

import Toast from "../components/Toast";
import { MQTT_STATUS } from "../constants/constants";
import LocalStorage from "../utils/LocalStorage";

const LedContainer = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  width: 30%;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 1rem 2rem;
  border-radius: 1.5rem;

  & h2 {
    text-align: center;
  }

  & h4 {
    margin: 0 1rem;
    margin-bottom: 1rem;
    opacity: 0.75;
  }
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1.5rem;
`;

const StyledHexColorPicker = styled(HexColorPicker)`
  &.react-colorful {
    width: 120px;
    height: 120px;
    margin-right: 30;
  }

  & .react-colorful__pointer {
    width: 18px;
    height: 18px;
  }
`;


export interface LedFormData {
  mode: SingleValue<OptionType>;
  brightness: number;
  speed: number;
  duration: number;
  colors: string[];
}

const initalLedFormData: LedFormData = {
  mode: animationOptions[1],
  brightness: 200,
  speed: 200,
  duration: 10,
  colors: ["#FF0000", "#00FF00", "#0000FF"],
};

const Led = (): React.ReactElement => {
  const { addressOptions, selectedNetID } = useSensorData();
  const [ledForm, setLedForm] = useState(initalLedFormData);
  const [address, setAddress] = useState<SingleValue<OptionType>>();
  const { client, connectionStatus } = useMqttState();
  const { handleSubmit } = useForm();

  useEffect(() => {
    setAddress(addressOptions[0]);
  }, [addressOptions]);

  const sendControl = () => {
    if (connectionStatus !== MQTT_STATUS.connected) {
      Toast({ type: "warning", text: "Mqtt not connected" });
      return;
    }

    const message = {
      type: "led-ring",
      payload: {
        ...ledForm,
        mode: ledForm.mode?.value,
        duration: ledForm.duration * 1000,
      },
    };
    client?.publish(
      `${LocalStorage.getMqttBaseTopic()}/${selectedNetID?.value}/${address?.value}/control`,
      JSON.stringify(message)
    );
    Toast({ type: "success", text: "LED animation sent!" });
  };

  const onChangeInput = ({ currentTarget: { name, value } }: FormEvent<HTMLInputElement>) => {
    setLedForm({ ...ledForm, [name]: Number(value) });
  };

  const onChangeSelect = (option: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    if (actionMeta.name && option) {
      setLedForm({ ...ledForm, [actionMeta.name]: option });
    }
  };

  return (
    <LedContainer>
      <form onSubmit={handleSubmit(sendControl)}>
        <h2>LED Ring Control</h2>
        <Select
          className="react-select"
          label="Address"
          name="address"
          value={address}
          options={addressOptions}
          onChange={(selected) => setAddress(selected)}
        />
        <Select
          className="react-select"
          label="Animation"
          name="mode"
          value={ledForm.mode}
          options={animationOptions}
          onChange={onChangeSelect}
        />
        <Input
          label="Brightness"
          name="brightness"
          lowerLabel="LED Ring brightness"
          type={"number"}
          value={ledForm.brightness}
          max={255}
          min={0}
          onChange={onChangeInput}
        />
        <Input
          label="Speed"
          name="speed"
          lowerLabel="Speed of the animation"
          type={"number"}
          value={ledForm.speed}
          max={255}
          min={0}
          onChange={onChangeInput}
        />
        <Input
          label="Duration"
          name="duration"
          lowerLabel="Duration of the animation in seconds"
          type={"number"}
          value={ledForm.duration}
          min={0}
          onChange={({ target }) =>
            setLedForm({
              ...ledForm,
              duration: Number(target.value),
            })
          }
        />
        <h4>Colors</h4>
        <ColorsContainer>
          <StyledHexColorPicker
            color={ledForm.colors[0]}
            onChange={(color) =>
              setLedForm({
                ...ledForm,
                colors: [color, ...ledForm.colors.slice(1)],
              })
            }
          />
          <StyledHexColorPicker
            color={ledForm.colors[1]}
            onChange={(color) =>
              setLedForm({
                ...ledForm,
                colors: [ledForm.colors[0], color, ledForm.colors[2]],
              })
            }
          />
          <StyledHexColorPicker
            color={ledForm.colors[2]}
            onChange={(color) =>
              setLedForm({
                ...ledForm,
                colors: [...ledForm.colors.slice(0, 2), color],
              })
            }
          />
        </ColorsContainer>
        <Button label="Send Animation" color="primary" type="submit" disabled={!address} />
      </form>
    </LedContainer>
  );
};

export default Led;
