import React from "react";

export default function Cog(): React.ReactElement {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6774 0H10.3226C4.62158 0 0 4.62158 0 10.3226V21.6774C0 27.3784 4.62158 32 10.3226 32H21.6774C27.3784 32 32 27.3784 32 21.6774V10.3226C32 4.62158 27.3784 0 21.6774 0Z" fill="#6BC28F"/>
      <path d="M24.3355 17.9613L23.3548 17.3892C22.2924 16.7355 22.2924 15.1828 23.3548 14.529L24.3355 13.9569C25.1526 13.4666 25.3978 12.486 24.9074 11.7505L24.0903 10.3613C23.6 9.54408 22.6193 9.29892 21.8838 9.78924L20.9032 10.3613C19.8408 11.015 18.4516 10.1978 18.4516 8.97204V7.82795C18.4516 6.92903 17.7161 6.19354 16.8171 6.19354H15.1827C14.2838 6.19354 13.5484 6.92903 13.5484 7.82795V8.89032C13.5484 10.1161 12.159 10.9334 11.0967 10.2796L10.1161 9.78924C9.29885 9.29892 8.3182 9.6258 7.90961 10.3613L7.0924 11.7505C6.6838 12.5677 6.92896 13.5484 7.66444 14.0387L8.64509 14.6108C9.70746 15.1828 9.70746 16.8172 8.64509 17.3892L7.66444 17.9613C6.84724 18.4516 6.60208 19.4323 7.0924 20.1677L7.90961 21.5569C8.39993 22.3742 9.38057 22.6193 10.1161 22.129L11.0967 21.6387C12.159 20.985 13.5484 21.8021 13.5484 23.0279V24.1721C13.5484 25.071 14.2838 25.8064 15.1827 25.8064H16.8171C17.7161 25.8064 18.4516 25.071 18.4516 24.1721V23.1097C18.4516 21.8839 19.8408 21.0666 20.9032 21.7205L21.8838 22.2924C22.701 22.7828 23.6816 22.4559 24.0903 21.7205L24.9074 20.3311C25.3161 19.4323 25.0709 18.4516 24.3355 17.9613ZM16 19.2688C14.2021 19.2688 12.7311 17.7979 12.7311 16C12.7311 14.2021 14.2021 12.7311 16 12.7311C17.7978 12.7311 19.2687 14.2021 19.2687 16C19.2687 17.7979 17.7978 19.2688 16 19.2688Z" fill="white"/>
    </svg>
  );
}
