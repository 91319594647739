import React from "react";

export default function StatusIcon(): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 0H10C4.47715 0 0 4.47715 0 10V22C0 27.5228 4.47715 32 10 32H22C27.5228 32 32 27.5228 32 22V10C32 4.47715 27.5228 0 22 0Z"
        fill="#F1B009"
      />
      <path
        d="M25.0426 25.0434H6.95654V6.9565H25.0426V25.0434ZM8.08692 23.913H23.9123V8.08694H8.08692V23.913Z"
        fill="white"
      />
      <path d="M28.9998 8.36952H25.0435V9.5H28.9998V8.36952Z" fill="white" />
      <path d="M28.9993 11.1957H25.043V12.3261H28.9993V11.1957Z" fill="white" />
      <path d="M28.9993 14.0218H25.043V15.1521H28.9993V14.0218Z" fill="white" />
      <path d="M28.9993 16.8479H25.043V17.9783H28.9993V16.8479Z" fill="white" />
      <path d="M28.9993 19.6739H25.043V20.8043H28.9993V19.6739Z" fill="white" />
      <path d="M28.9993 22.5H25.043V23.6305H28.9993V22.5Z" fill="white" />
      <path d="M6.95682 8.36952H3.00049V9.5H6.95682V8.36952Z" fill="white" />
      <path d="M6.95633 11.1957H3V12.3261H6.95633V11.1957Z" fill="white" />
      <path d="M6.95633 14.0218H3V15.1521H6.95633V14.0218Z" fill="white" />
      <path d="M6.95633 16.8479H3V17.9783H6.95633V16.8479Z" fill="white" />
      <path d="M6.95633 19.6739H3V20.8043H6.95633V19.6739Z" fill="white" />
      <path d="M6.95633 22.5H3V23.6305H6.95633V22.5Z" fill="white" />
      <path d="M23.6298 3H22.4995V6.95652H23.6298V3Z" fill="white" />
      <path d="M20.8032 3H19.6729V6.95652H20.8032V3Z" fill="white" />
      <path d="M17.9781 3H16.8477V6.95652H17.9781V3Z" fill="white" />
      <path d="M15.1515 3H14.021V6.95652H15.1515V3Z" fill="white" />
      <path d="M12.3261 3H11.1958V6.95652H12.3261V3Z" fill="white" />
      <path d="M9.50044 3H8.37012V6.95652H9.50044V3Z" fill="white" />
      <path d="M23.6295 25.0434H22.499V29H23.6295V25.0434Z" fill="white" />
      <path d="M20.8032 25.0434H19.6729V29H20.8032V25.0434Z" fill="white" />
      <path d="M17.9775 25.0434H16.8472V29H17.9775V25.0434Z" fill="white" />
      <path d="M15.1515 25.0434H14.021V29H15.1515V25.0434Z" fill="white" />
      <path d="M12.3256 25.0434H11.1953V29H12.3256V25.0434Z" fill="white" />
      <path d="M9.49947 25.0434H8.36914V29H9.49947V25.0434Z" fill="white" />
      <path
        d="M9.61745 8.81763L8.81836 9.61713L9.62694 10.4255L10.426 9.6261L9.61745 8.81763Z"
        fill="white"
      />
      <path
        opacity="0.9"
        d="M16 10L11 16H13.9167V22H18.0833V16H21L16 10Z"
        fill="white"
      />
    </svg>
  );
}
