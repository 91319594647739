import "react-toastify/dist/ReactToastify.css";
import React, { FC } from "react";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import { BASENAME, ROUTES } from "./constants/constants";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./components/Navigation/Sidebar";
import SensorDataProvider from "./context/SensorDataContext";
import { DBConfig } from "./constants/DBConfig";
import { initDB } from "react-indexed-db";
import { Connector } from "mqtt-react-hooks";
import LocalStorage from "./utils/LocalStorage";
import ToastContainer from "./components/ToastContainer";
import SessionStorage from "./utils/SessionStorage";

const MainContainer = styled.div`
  height: 100vh;
  border-top-left-radius: 1.6rem;
  border-bottom-left-radius: 1.6rem;
  position: relative;
  overflow-y: overlay;
  overflow-x: hidden;
  background-color: #f6f6f6;

  @media screen and (max-width: 
  ${(p) =>  p.theme.mediaQueries.tablet}) and (min-width: ${(p) =>
  p.theme.mediaQueries.mobile}) {
    overflow-x: auto;
    overflow-x: overlay;
    width: 90%;
    position: absolute;
    right: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    position: initial;
    width: 100%;
    right: initial;
    border-radius: 0;
    overflow: initial;
    height: initial;
    min-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 4rem;
    /* // FIXME: hack so padding on top of page correctly extends the div */
    padding-top: 0.01rem;
  }
`;

initDB(DBConfig);

const getRoutes = (): React.ReactElement[] =>
  Object.keys(ROUTES).map((key, index) => {
    const route = ROUTES[key as keyof typeof ROUTES];
    return <Route key={index} path={route.href} component={route.component} exact />;
  });

const App: FC = () => {
  return (
    <Connector
      brokerUrl={LocalStorage.getMqttURL()}
      options={{
        port: Number(LocalStorage.getMqttPort()),
        path: "/mqtt",
        rejectUnauthorized: false,
        username: LocalStorage.getMqttUsername(),
        password: SessionStorage.getMqttPassword(),
      }}
    >
      <SensorDataProvider>
        <Router basename={BASENAME} forceRefresh>
          <Sidebar />
          <MainContainer>
            <Switch>{getRoutes()}</Switch>
          </MainContainer>
          <ToastContainer />
        </Router>
      </SensorDataProvider>
    </Connector>
  );
};

export default App;
