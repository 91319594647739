import React, { FormEvent, useState } from "react";
import { Input } from "../components/shared-components/Input";
import { useMqttState } from "mqtt-react-hooks";
import styled from "styled-components";
import Button from "../components/shared-components/Button";
import { useHistory } from "react-router";
import LocalStorage, { PROTOCOLS } from "../utils/LocalStorage";
import { RegisterOptions, useForm } from "react-hook-form";
import { REQUIRED_FIELD_RULES } from "../constants/constants";
import SessionStorage from "../utils/SessionStorage";

const MqttContainer = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  border-radius: 1.5rem;
  width: 25%;
  margin: 0 auto;
  margin-top: 10rem;
  padding: 1rem 2rem;

  & h2 {
    text-align: center;
  }

  & img {
    display: none;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 65%;
    padding: 1.6rem 1rem;

    & form {
      justify-content: left;
    }

    & button {
      flex: none;
    }

    & img {
      display: inline;
    }
  }
`;

export interface MqttFormData {
  url: string;
  port: string;
  topic: string;
  username: string;
  password: string;
}

const initalMqttFormData: MqttFormData = {
  url: LocalStorage.getMqttURL(),
  port: LocalStorage.getMqttPort(),
  topic: LocalStorage.getMqttTopic(),
  username: LocalStorage.getMqttUsername(),
  password: SessionStorage.getMqttPassword(),
};

const urlRule: RegisterOptions = {
  required: "Field is required",
  pattern: {
    value: new RegExp(PROTOCOLS + "[a-z0-9-]+(.[a-z0-9-]+)+$", "i"),
    message: "This is not valid URL",
  },
};

const topicRule: RegisterOptions = {
  required: "Field is required",
  pattern: {
    value: /^([a-z0-9-]|\/)?([a-z0-9-]+\/|\+\/)+([a-z0-9-]+\/?|\+\/?)$/,
    message: "This is not valid topic",
  },
};

const Mqtt = (): React.ReactElement => {
  const [mqttForm, setMqttForm] = useState(initalMqttFormData);
  const { client } = useMqttState();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onChangeInput = ({ currentTarget: { name, value } }: FormEvent<HTMLInputElement>) => {
    setMqttForm({ ...mqttForm, [name]: value });
  };

  const saveMqttConfiguration = () => {
    client?.end();
    LocalStorage.setMqttURL(mqttForm.url);
    LocalStorage.setMqttPort(mqttForm.port);
    LocalStorage.setMqttTopic(mqttForm.topic);
    LocalStorage.setMqttUsername(mqttForm.username);
    SessionStorage.setMqttPassword(mqttForm.password);
    history.push("/");
  };

  return (
    <MqttContainer>
      <h2>Mqtt Configuration</h2>
      <form onSubmit={handleSubmit(saveMqttConfiguration)}>
        <Input
          label="MQTT Broker URL"
          name="url"
          lowerLabel="Only WebSocket Brokers are supported."
          value={mqttForm.url}
          register={register}
          rules={urlRule}
          invalid={errors.url}
          feedbackText={errors?.url?.message}
          onChange={onChangeInput}
        />
        <Input
          label="MQTT Broker Port"
          name="port"
          lowerLabel="Only WebSocket Brokers are supported."
          type={"number"}
          value={mqttForm.port}
          min={1}
          max={65535}
          register={register}
          rules={REQUIRED_FIELD_RULES}
          invalid={errors.port}
          feedbackText={errors?.port?.message}
          onChange={onChangeInput}
        />
        <Input
          label="MQTT Broker Topic"
          name="topic"
          lowerLabel="e.g. /sauter/viasens/+/+"
          value={mqttForm.topic}
          register={register}
          rules={topicRule}
          invalid={errors.topic}
          feedbackText={errors?.topic?.message}
          onChange={onChangeInput}
        />
        <Input label="MQTT username" name="username" value={mqttForm.username} onChange={onChangeInput} />
        <Input
          label="MQTT password"
          name="password"
          type="password"
          value={mqttForm.password}
          onChange={onChangeInput}
        />
        <Button label="Save Changes" color="primary" type="submit" />
      </form>
    </MqttContainer>
  );
};

export default Mqtt;
