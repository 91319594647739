import { OptionType } from "./types";

const animation_registry = {
  "CLEAR LED RING"                :    -1,
  "FX_MODE_STATIC"                :    0,
  "FX_MODE_BLINK"                 :    1,
  "FX_MODE_BREATH"                :    2,
  "FX_MODE_COLOR_WIPE"            :    3,
  "FX_MODE_COLOR_WIPE_RANDOM"     :    4,
  "FX_MODE_RANDOM_COLOR"          :    5,
  "FX_MODE_COLOR_SWEEP"           :    6,
  "FX_MODE_DYNAMIC"               :    7,
  "FX_MODE_RAINBOW"               :    8,
  "FX_MODE_RAINBOW_CYCLE"         :    9,
  "FX_MODE_SCAN"                  :   10,
  "FX_MODE_DUAL_SCAN"             :   11,
  "FX_MODE_FADE"                  :   12,
  "FX_MODE_THEATER_CHASE"         :   13,
  "FX_MODE_THEATER_CHASE_RAINBOW" :   14,
  "FX_MODE_RUNNING_LIGHTS"        :   15,
  "FX_MODE_SAW"                   :   16,
  "FX_MODE_TWINKLE"               :   17,
  "FX_MODE_DISSOLVE"              :   18,
  "FX_MODE_DISSOLVE_RANDOM"       :   19,
  "FX_MODE_SPARKLE"               :   20,
  "FX_MODE_FLASH_SPARKLE"         :   21,
  "FX_MODE_HYPER_SPARKLE"         :   22,
  "FX_MODE_STROBE"                :   23,
  "FX_MODE_STROBE_RAINBOW"        :   24,
  "FX_MODE_MULTI_STROBE"          :   25,
  "FX_MODE_BLINK_RAINBOW"         :   26,
  "FX_MODE_ANDROID"               :   27,
  "FX_MODE_CHASE_COLOR"           :   28,
  "FX_MODE_CHASE_RANDOM"          :   29,
  "FX_MODE_CHASE_RAINBOW"         :   30,
  "FX_MODE_CHASE_FLASH"           :   31,
  "FX_MODE_CHASE_FLASH_RANDOM"    :   32,
  "FX_MODE_CHASE_RAINBOW_WHITE"   :   33,
  "FX_MODE_COLORFUL"              :   34,
  "FX_MODE_TRAFFIC_LIGHT"         :   35,
  "FX_MODE_COLOR_SWEEP_RANDOM"    :   36,
  "FX_MODE_RUNNING_COLOR"         :   37,
  "FX_MODE_RUNNING_RED_BLUE"      :   38,
  "FX_MODE_RUNNING_RANDOM"        :   39,
  "FX_MODE_LARSON_SCANNER"        :   40,
  "FX_MODE_COMET"                 :   41,
  "FX_MODE_FIREWORKS"             :   42,
  "FX_MODE_RAIN"                  :   43,
  "FX_MODE_MERRY_CHRISTMAS"       :   44,
  "FX_MODE_FIRE_FLICKER"          :   45,
  "FX_MODE_GRADIENT"              :   46,
  "FX_MODE_LOADING"               :   47,
  "FX_MODE_POLICE"                :   48,
  "FX_MODE_POLICE_ALL"            :   49,
  "FX_MODE_TWO_DOTS"              :   50,
  "FX_MODE_TWO_AREAS"             :   51,
  "FX_MODE_CIRCUS_COMBUSTUS"      :   52,
  "FX_MODE_HALLOWEEN"             :   53,
  "FX_MODE_TRICOLOR_CHASE"        :   54,
  "FX_MODE_TRICOLOR_WIPE"         :   55,
  "FX_MODE_TRICOLOR_FADE"         :   56,
  "FX_MODE_LIGHTNING"             :   57,
  "FX_MODE_ICU"                   :   58,
  "FX_MODE_MULTI_COMET"           :   59,
  "FX_MODE_DUAL_LARSON_SCANNER"   :   60,
  "FX_MODE_RANDOM_CHASE"          :   61,
  "FX_MODE_OSCILLATE"             :   62,
  "FX_MODE_PRIDE_2015"            :   63,
  "FX_MODE_JUGGLE"                :   64,
  "FX_MODE_PALETTE"               :   65,
  "FX_MODE_FIRE_2012"             :   66,
  "FX_MODE_COLORWAVES"            :   67,
  "FX_MODE_BPM"                   :   68,
  "FX_MODE_FILLNOISE8"            :   69,
  "FX_MODE_NOISE16_1"             :   70,
  "FX_MODE_NOISE16_2"             :   71,
  "FX_MODE_NOISE16_3"             :   72,
  "FX_MODE_NOISE16_4"             :   73,
  "FX_MODE_COLORTWINKLE"          :   74,
  "FX_MODE_LAKE"                  :   75,
  "FX_MODE_METEOR"                :   76,
  "FX_MODE_METEOR_SMOOTH"         :   77,
  "FX_MODE_RAILWAY"               :   78,
  "FX_MODE_RIPPLE"                :   79,
  "FX_MODE_TWINKLEFOX"            :   80,
  "FX_MODE_TWINKLECAT"            :   81,
  "FX_MODE_HALLOWEEN_EYES"        :   82,
  "FX_MODE_STATIC_PATTERN"        :   83,
  "FX_MODE_TRI_STATIC_PATTERN"    :   84,
  "FX_MODE_SPOTS"                 :   85,
  "FX_MODE_SPOTS_FADE"            :   86,
  "FX_MODE_GLITTER"               :   87,
  "FX_MODE_CANDLE"                :   88,
  "FX_MODE_STARBURST"             :   89,
  "FX_MODE_EXPLODING_FIREWORKS"   :   90,
  "FX_MODE_BOUNCINGBALLS"         :   91,
  "FX_MODE_SINELON"               :   92,
  "FX_MODE_SINELON_DUAL"          :   93,
  "FX_MODE_SINELON_RAINBOW"       :   94,
  "FX_MODE_POPCORN"               :   95,
  "FX_MODE_DRIP"                  :   96,
  "FX_MODE_PLASMA"                :   97,
  "FX_MODE_PERCENT"               :   98,
  "FX_MODE_RIPPLE_RAINBOW"        :   99,
  "FX_MODE_HEARTBEAT"             :  100,
  "FX_MODE_PACIFICA"              :  101,
  "FX_MODE_CANDLE_MULTI"          :  102,
  "FX_MODE_SOLID_GLITTER"         :  103,
  "FX_MODE_SUNRISE"               :  104,
  "FX_MODE_PHASED"                :  105,
  "FX_MODE_TWINKLEUP"             :  106,
  "FX_MODE_NOISEPAL"              :  107,
  "FX_MODE_SINEWAVE"              :  108,
  "FX_MODE_PHASEDNOISE"           :  109,
  "FX_MODE_FLOW"                  :  110,
  "FX_MODE_CHUNCHUN":  111,
  "FX_MODE_DANCING_SHADOWS":  112
};

export const animationOptions: OptionType[] = Object.entries(animation_registry).map( animation => {
  return {
    value: animation[1],
    label: animation[0]
  };
});
