import React from "react";
import styled from "styled-components";

const SidebarContainer = styled.div`
  width: 18%;
  max-width: 20rem;
  height: 100vh;
  float: left;
  box-sizing: border-box;
  padding: 3.5rem 2rem 0.8rem;
  transition: ease-in-out 0.3s;

  overflow-y: overlay;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  
  background-color: #EAEAEA;
  
  @media screen and (max-width: ${(p) =>
    p.theme.mediaQueries.tablet}) and (min-width: ${(p) =>
  p.theme.mediaQueries.mobile}) {
    width: 10%;
    padding-left: 0;
    padding-right: 0;
    z-index: 20;
    position: relative;
    max-width: initial;

    &:hover {
      .link-label,
      .logo-label {
        display: none;
      }
      .link-bar {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 100%;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    padding: 0;
    height: initial;
    z-index: 20;
    justify-content: space-evenly;
    max-width: initial;

    &:hover {
      width: 100%;
      padding: 0;

      .link-label,
      .logo-label {
        display: none;
      }
      .link-bar {
        display: none;
      }
    }
  }
`;

export function Sidebar({
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  return <SidebarContainer {...props} />;
}

const HeaderContainer = styled.div`
  margin-left: 0.7rem;
  flex: 0 1 10rem;

  word-break: normal;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    margin-left: 0;
    margin-bottom: 0;

    text-align: center;   
      
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    display: none;
  }
`;

export function Header({
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  return <HeaderContainer {...props} />;
}

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  flex-grow: 1;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    display: none;
  }
`;

export function Footer({
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  return <FooterContainer {...props} />;
}
