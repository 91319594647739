import React from "react";

export default function MqttIcon(): React.ReactElement {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_9:2" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" rx="10" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_9:2)">
        <path d="M-0.238281 13.896V19.1015C7.98745 19.1126 14.6973 25.7115 14.8088 33.8597H19.8802C19.7576 22.8914 10.7962 13.9963 -0.238281 13.896Z" fill="white"/>
        <path d="M-0.238281 3.12836V8.33386C13.9617 8.34501 25.5201 19.7592 25.6315 33.8486H30.7029C30.5915 16.9614 16.7705 3.23982 -0.238281 3.12836Z" fill="white"/>
        <path d="M33.8498 20.5393V9.84968C32.5346 8.05506 30.963 6.12669 29.3469 4.52157C27.5635 2.74924 25.5461 1.16641 23.5175 -0.238068H12.2378C22.0797 3.30658 29.971 10.9198 33.8498 20.5393Z" fill="white"/>
        <path d="M-0.238281 19.1014V32.1542C-0.238281 33.0905 0.519645 33.8596 1.46705 33.8596H14.7976C14.6862 25.7002 7.98745 19.1126 -0.238281 19.1014Z" fill="#660066"/>
        <path d="M-0.238281 8.34497V13.896C10.7962 13.9963 19.7576 22.8914 19.8691 33.8597H25.6315C25.5201 19.7703 13.9617 8.35612 -0.238281 8.34497Z" fill="#660066"/>
        <path d="M33.8461 32.143V20.5393C29.9673 10.9198 22.0759 3.30658 12.2341 -0.238068H1.46705C0.53079 -0.238068 -0.238281 0.519907 -0.238281 1.46737V3.13938C16.7705 3.2397 30.5915 16.9613 30.7029 33.8596H32.1519C33.0882 33.8485 33.8461 33.0905 33.8461 32.143Z" fill="#660066"/>
        <path d="M29.3568 4.52157C30.9729 6.12669 32.5445 8.05507 33.8597 9.84969V1.46738C33.8597 0.531054 33.1018 -0.238068 32.1544 -0.238068H23.5386C25.5448 1.16642 27.5734 2.74925 29.3568 4.52157Z" fill="#660066"/>
      </g>
    </svg>
  );
}


