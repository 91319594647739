import { useMqttState } from "mqtt-react-hooks";
import React, { FC, useEffect } from "react";
import ChartContainer from "../components/ChartContainer";
import Toast, { Dismiss } from "../components/Toast";

const DashboardPage: FC = () => {
  const { connectionStatus } = useMqttState();

  useEffect(() => {
    switch (connectionStatus) {
      case "Offline":
        Toast({type: "warning", text: connectionStatus});
        break;
      case "Connected":
        Dismiss();
        Toast({type: "success", text: connectionStatus});
        break;
      case "Reconnecting":
        Toast({type: "warning", text: connectionStatus, autoClose: false});
        break;
      case "Closed":
        Toast({type: "warning", text: connectionStatus});
        break;
      case "Error":
        Toast({type: "error", text: connectionStatus});
        break;
      default:
        return;
    }
  }, [connectionStatus]);

  return <ChartContainer />;
};

export default DashboardPage;
