import React from "react";

import styled from "styled-components";

const Icon = styled.svg`
  position: absolute;
  right: 44px;
  bottom: 7px;
  cursor: pointer;

  & path {
    fill: #282828;
  }
`;

export function OpenEye(
  props: React.SVGAttributes<SVGElement>
): React.ReactElement {
  return (
    <Icon
      width="17"
      height="16"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.25757 13.963C6.93757 13.943 5.71758 13.563 4.57758 12.923C2.81758 11.943 1.39758 10.563 0.197575 8.94302C-0.0624249 8.60302 -0.062425 8.36302 0.177575 8.04302C1.43758 6.34302 2.93758 4.88302 4.83758 3.90302C5.95758 3.32302 7.15757 2.96302 8.43757 3.00302C9.67757 3.04302 10.8176 3.42302 11.8976 4.02302C13.6776 5.00302 15.1176 6.38302 16.3176 8.02302C16.5776 8.38302 16.5776 8.58302 16.3176 8.92302C15.0776 10.583 13.6376 11.983 11.8176 12.983C10.6976 13.583 9.51757 13.943 8.25757 13.963ZM8.21758 12.823C8.99758 12.803 9.75757 12.643 10.4976 12.343C12.3976 11.543 13.8576 10.203 15.1376 8.62302C15.2376 8.50302 15.2176 8.42302 15.1176 8.32302C14.9376 8.10302 14.7576 7.88302 14.5576 7.66302C13.4976 6.48302 12.2976 5.46302 10.8376 4.78302C9.99757 4.38302 9.09757 4.12302 8.15757 4.14302C7.09757 4.16302 6.11758 4.50302 5.19758 4.98302C3.67758 5.80302 2.45758 6.96302 1.37758 8.28302C1.25758 8.42302 1.27758 8.52302 1.37758 8.66302C2.23758 9.74302 3.21758 10.703 4.35758 11.483C5.53758 12.243 6.79758 12.783 8.21758 12.823Z"
        fill="black"
      />
      <path
        d="M11.1976 8.48302C11.1976 10.083 9.87757 11.423 8.25757 11.423C6.63757 11.443 5.33757 10.123 5.29757 8.46302C5.27757 6.88302 6.65757 5.52302 8.25757 5.52302C9.85757 5.52302 11.1776 6.84302 11.1976 8.48302ZM6.43757 8.44302C6.43757 9.46302 7.23757 10.283 8.23757 10.283C9.21757 10.283 10.0576 9.46302 10.0576 8.48302C10.0576 7.48302 9.23757 6.66302 8.25757 6.66302C7.27757 6.66302 6.43757 7.48302 6.43757 8.44302Z"
        fill="black"
      />
      <path d="M3 4.5L1 2.5" stroke="black" />
      <path d="M14 4.5L16 2.5" stroke="black" />
      <path d="M8.5 2.5L8.5 0" stroke="black" />
    </Icon>
  );
}

export function ClosedEye(
  props: React.SVGAttributes<SVGElement>
): React.ReactElement {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="eye-off-2">
          <rect width="24" height="24" opacity="0" />
          <path d="M17.81 13.39A8.93 8.93 0 0 0 21 7.62a1 1 0 1 0-2-.24 7.07 7.07 0 0 1-14 0 1 1 0 1 0-2 .24 8.93 8.93 0 0 0 3.18 5.77l-2.3 2.32a1 1 0 0 0 1.41 1.41l2.61-2.6a9.06 9.06 0 0 0 3.1.92V19a1 1 0 0 0 2 0v-3.56a9.06 9.06 0 0 0 3.1-.92l2.61 2.6a1 1 0 0 0 1.41-1.41z" />
        </g>
      </g>
    </Icon>
  );
}
