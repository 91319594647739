import React from "react";

export default function FileIcon(props: React.SVGAttributes<SVGElement>): React.ReactElement {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.49121 15.6122C11.5859 15.6122 14.9053 12.3059 14.9053 8.22747C14.9053 4.14901 11.5859 0.842773 7.49121 0.842773C3.39654 0.842773 0.0771484 4.14901 0.0771484 8.22747C0.0771484 12.3059 3.39654 15.6122 7.49121 15.6122Z"
        fill="#FF6363"
      />
      <path
        d="M9.31394 5.04895L7.49045 6.86533L5.66708 5.04906C5.48573 4.86842 5.23977 4.76695 4.9833 4.76696C4.72683 4.76697 4.48087 4.86846 4.29951 5.04911C4.11815 5.22976 4.01626 5.47477 4.01625 5.73024C4.01625 5.98571 4.11812 6.23071 4.29946 6.41135L6.12283 8.22762L4.29934 10.044C4.11799 10.2247 4.0161 10.4697 4.01609 10.7251C4.01608 10.9806 4.11795 11.2256 4.2993 11.4063C4.48064 11.5869 4.72661 11.6884 4.98308 11.6884C5.23955 11.6884 5.48551 11.5869 5.66687 11.4062L7.49036 9.58982L9.31374 11.4061C9.49508 11.5867 9.74105 11.6882 9.99752 11.6882C10.254 11.6882 10.5 11.5867 10.6813 11.406C10.8627 11.2254 10.9646 10.9804 10.9646 10.7249C10.9646 10.4694 10.8627 10.2244 10.6814 10.0438L8.85798 8.22753L10.6815 6.41115C10.8628 6.2305 10.9647 5.98549 10.9647 5.73002C10.9647 5.47455 10.8629 5.22954 10.6815 5.0489C10.5002 4.86826 10.2542 4.76678 9.99774 4.76679C9.74127 4.7668 9.4953 4.8683 9.31394 5.04895Z"
        fill="white"
      />
    </svg>
  );
}
