import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import App from "./App";
import { IDefaultTheme, LightTheme } from "./constants/theme.config";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const GlobalStyle = createGlobalStyle<{ theme: IDefaultTheme }>`
  body {
    padding:0;
    margin:0;
    color: ${(p) => p.theme.globalStyling.color};
    background-color: ${(p) => p.theme.globalStyling.background};

    font-family: ${(p) => p.theme.globalStyling.fontFamily};
    font-size: ${(p) => p.theme.globalStyling.fontSize};
  }
  html {
    overflow-y: overlay;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  a {
    color: ${(p) => p.theme.components.anchor.color};
  }


  @media screen and (min-width: ${(p) => p.theme.mediaQueries.tablet}) {
    .hide-tooltip {
      display: none;
    }
  }
  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    .Toastify__toast-container {
      padding: 0.5rem !important;
    }
    .hide-tooltip {
      display: none;
    }
    .mobile-tooltip {
      max-width: 6rem;
    }
  }
`;

ReactDOM.render(
  <ThemeProvider theme={LightTheme}>
    <React.StrictMode>
      <GlobalStyle />
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorkerRegistration.register();
