import { Chart as ChartJS, Line, defaults } from "react-chartjs-2";
import React, { FC, useState, useEffect, useRef } from "react";
import { colorPallete } from "../constants/theme.config";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SensorData } from "../constants/types";
import { useSensorData } from "../context/SensorDataContext";
import Color from "color";

const UPDATE_TIMER = 1000;

ChartJS.register(ChartDataLabels);
interface ChartProps {
  title: string;
  samples: number;
  data: SensorData | null;
}

interface IDataset {
  label: string;
  data: number[];
  fill: boolean;
  hidden: boolean;
  borderColor: string;
  backgroundColor: string;
  pointBackgroundColor: string[];
  pointRadius: number[];
}

defaults.set("plugins.datalabels", {
  anchor: "end",
  align: "right",
  display: true,
  clamp: true,
  borderRadius: 5,
  backgroundColor: function (context: any) {
    if (context.dataIndex === context.dataset.data.length - 1) {
      return context.dataset.backgroundColor;
    }
  },
  color: "white",
  formatter: function (value: number, context: any) {
    if (context.dataIndex === context.dataset.data.length - 1) {
      return value.toFixed(2);
    }
    return "";
  },
});

const POINT_RADIUS = 3;

const Chart: FC<ChartProps> = ({ title, samples, data }: ChartProps) => {
  const [sensorDatasets, setSensorDatasets] = useState<IDataset[]>([]);
  const [addr, setAddr] = useState<number[]>([]);
  const { nodeAddresses, linesToShow, selectedNetID } = useSensorData();
  const prevNetID = useRef<number>();
  const chartRef = useRef<HTMLCanvasElement>();

  const chartData = {
    labels: Array.from(Array(samples).keys()).map((x) => `${x}`),
    datasets: sensorDatasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      y: {
        duration: 0,
      },
      x: {
        duration: 0,
      },
    },
    spanGaps: true,
    layout: {
      padding: {
        right: 50,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        display: true,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: true,
        align: "start",
        position: "bottom",
      },
    },
  };

  const generateNewDataset = (label: string, idx: number): IDataset => {
    const color = Color(colorPallete[idx]).alpha(0.5).lighten(0.2).toString();

    return {
      label: label,
      data: [0],
      fill: false,
      hidden: false,
      borderColor: color,
      backgroundColor: Color(colorPallete[idx]).toString(),
      pointBackgroundColor: [color],
      pointRadius: [POINT_RADIUS],
    };
  };

  const handleNetIDChange = () => {
    setSensorDatasets([]);
    setAddr([]);
    if (chartRef.current) {
      const chart = ChartJS.getChart(chartRef.current);
      chart?.stop().render();
    }
  };

  useEffect(() => {
    handleNetIDChange();
  }, [selectedNetID]);

  useEffect(() => {
    if (prevNetID.current !== selectedNetID?.value) {
      prevNetID.current = selectedNetID?.value;
      return;
    }
    const updateData = sensorDatasets.map((dataset) => {
      const addr = Number(dataset.label.split("_")[0]);
      if (linesToShow.includes(addr)) {
        dataset.hidden = false;
      } else {
        dataset.hidden = true;
      }
      return dataset;
    });

    setSensorDatasets(updateData);
  }, [linesToShow, selectedNetID]);

  useEffect(() => {
    if (data === null) return;

    const { address, payload } = data;
    const types = Object.keys(payload);

    if (!addr.includes(data.address)) {
      types.forEach((type, idx) => {
        const label = address + "_" + type;
        const colorIndex = nodeAddresses.indexOf(address) * 2 + idx;
        setSensorDatasets((prevData) => [...prevData, generateNewDataset(label, colorIndex)]);
      });
      setAddr([...addr, address]);
      return;
    }

    const shiftChart = setInterval(() => {
      const updateData = sensorDatasets.map((dataset) => {
        if (dataset.data.length >= samples) {
          dataset.data.shift();
          dataset.pointBackgroundColor.shift();
          dataset.pointRadius.shift();
        }

        const type = types.find((type) => dataset.label === address + "_" + type);
        if (type) {
          if (dataset.data[dataset.data.length - 1] !== payload[type]) {
            dataset.pointBackgroundColor.push(dataset.backgroundColor);
            dataset.pointRadius.push(POINT_RADIUS + 2);
          } else {
            dataset.pointBackgroundColor.push(dataset.borderColor);
            dataset.pointRadius.push(POINT_RADIUS);
          }
          dataset.data.push(payload[type]);
        } else {
          dataset.data.push(dataset.data[dataset.data.length - 1]);
          dataset.pointBackgroundColor.push(dataset.borderColor);
          dataset.pointRadius.push(POINT_RADIUS);
        }

        return dataset;
      });

      setSensorDatasets(updateData);
    }, UPDATE_TIMER);

    return () => {
      clearInterval(shiftChart);
    };
  }, [sensorDatasets, data]);

  return (
    <>
      <Line ref={chartRef} id={title} data={chartData} options={options} />
    </>
  );
};

export default Chart;
