import React from "react";

interface NetIDIconProps extends React.SVGAttributes<SVGElement> {
  networkID: string;
}

export default function NetIDIcon({ networkID, ...props }: NetIDIconProps): React.ReactElement {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6774 0H10.3226C4.62158 0 0 4.62158 0 10.3226V21.6774C0 27.3784 4.62158 32 10.3226 32H21.6774C27.3784 32 32 27.3784 32 21.6774V10.3226C32 4.62158 27.3784 0 21.6774 0Z"
        fill="#7768D4"
      />
      <text x="50%" y="56%" fill="white" dominantBaseline="middle" textAnchor="middle" fontWeight={"bold"}>
        {networkID}
      </text>
    </svg>
  );
}
