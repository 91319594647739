import Cog from "../components/SvgIcons/Cog";
import Download from "../components/SvgIcons/Download";
import MqttIcon from "../components/SvgIcons/MqttIcon";
import DashboardIcon from "../components/SvgIcons/DashboardIcon";
import LedRingIcon from "../components/SvgIcons/LedRingIcon";
import dashboard from "../pages/dashboard";
import Led from "../pages/Led";
import Log from "../pages/Log";
import Mqtt from "../pages/Mqtt";
import Node from "../pages/Node";
import NotFound from "../pages/NotFound";
import { IMqttStatus, IRoutes, OptionType } from "./types";
import Flash from "../pages/Flash";
import { RegisterOptions } from "react-hook-form";
import Status from "../pages/Status";
import StatusIcon from "../components/SvgIcons/InfoIcon";
import FlashIcon from "../components/SvgIcons/FlashIcon";
import NetIDIcon from "../components/SvgIcons/NetIDIcon";
import DocumentationIcon from "../components/SvgIcons/DocumentationIcon";

export const BASENAME = process.env.REACT_APP_FRONTEND_BASENAME || "";

export const REQUIRED_FIELD_RULES: RegisterOptions = {
  required: "Field is required",
};

export const BASE_ROUTE = "";

export const LATEST_PRERELEASE = "https://sbaprodfirmware.blob.core.windows.net/fmsfirmware/releases/fms-fw-latest.zip";
export const LATEST_RELEASE =
  "https://sbaprodfirmware.blob.core.windows.net/fmsfirmware/releases-world/fms-fw-latest.zip";
export const SUPRABIT_RELEASE = "https://smart-sensor-firmware.suprabit.eu/smart-sensor-firmware-v0.8b7f1aa76d.zip";
export const MANIFEST_FILE_NAME = "manifest.json";

export const DOCUMENTATION_URL = "https://support.iot.sauter-cloud.com/fms-manual/";

export const ROUTES: IRoutes = {
  dashboard: {
    label: "Dashboard",
    href: `${BASE_ROUTE}/`,
    icon: DashboardIcon(),
    component: dashboard,
  },
  mqtt: {
    label: "Mqtt",
    href: `${BASE_ROUTE}/mqtt`,
    icon: MqttIcon(),
    component: Mqtt,
  },
  node: {
    label: "Node",
    href: `${BASE_ROUTE}/node`,
    icon: Cog(),
    component: Node,
  },
  led: {
    label: "Led",
    href: `${BASE_ROUTE}/led`,
    icon: LedRingIcon(),
    component: Led,
  },
  log: {
    label: "Log",
    href: `${BASE_ROUTE}/log`,
    icon: Download(),
    component: Log,
  },
  status: {
    label: "Status",
    href: `${BASE_ROUTE}/status`,
    icon: StatusIcon(),
    component: Status,
  },
  flash: {
    label: "Flash Tool",
    href: `${BASE_ROUTE}/flash`,
    icon: FlashIcon(),
    component: Flash,
  },
  netID: {
    label: "Net ID",
    icon: NetIDIcon({ networkID: "ID" }),
    component: undefined,
  },
  documentation: {
    label: "Documentation",
    icon: DocumentationIcon(),
    onClick: () => window.open(DOCUMENTATION_URL),
  },
  notFound: {
    label: "404",
    href: "*",
    component: NotFound,
  },
};

export const MQTT_STATUS: IMqttStatus = {
  offline: "Offline",
  reconnecting: "Reconnecting",
  connected: "Connected",
  closed: "Closed",
  error: "Error",
};

export const MQTT_TOPIC_SUFFIX_CONTROL = "control";
export const MQTT_TOPIC_SUFFIX_CONFIGURE = "configure";
export const MQTT_TOPIC_SUFFIX_PRESENCE = "presence";
export const MQTT_TOPIC_SUFFIX_STATUS = "status";
export const MQTT_TOPIC_SUFFIX_UPDATE = "update";

export enum URL_TYPES {
  "LATEST PRERELEASE",
  "LATEST RELEASE",
  "BETA RELEASE",
  "CUSTOM RELEASE",
}

export const urlOptions: OptionType[] = [
  { value: URL_TYPES["LATEST RELEASE"], label: LATEST_RELEASE },
  {
    value: URL_TYPES["LATEST PRERELEASE"],
    label: LATEST_PRERELEASE,
    description: "This is an unsupported pre-release coming without any support",
  },
];

export const ERROR_MESSAGE: { [key: string]: { message: string } } = {
  required: {
    message: "This field is required",
  },
};
