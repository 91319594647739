import React, { HTMLAttributes } from "react";
import styled from "styled-components";

export const Container = styled.div`
  font-size: 0.8rem;

  color: ${(p) => p.theme.components.anchor.color};

  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  & i {
    cursor: default;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    & i {
      color: #eceece;
    }
  }
`;

export default function LinkBar(props: HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const currentYear = new Date().getFullYear();
  return (
    <Container {...props}>
      <i>Fr. Sauter AG © {currentYear}</i>
    </Container>
  );
}
