/* eslint-disable @typescript-eslint/no-explicit-any */
export function debounce<T extends (...params: any[]) => void>(
  fn: T,
  delay: number
): (this: any, ...args: any[]) => void {
  let timeoutID: number;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as T;
}
