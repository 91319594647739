import React from "react";

export default function StatusIcon(): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H22C27.5228 0 32 4.47715 32 10V22C32 27.5228 27.5228 32 22 32H10C4.47715 32 0 27.5228 0 22V10Z"
        fill="#6CC1E6"
      />
      <path
        d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z"
        fill="#FCFCFC"
      />
      <path
        d="M24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16Z"
        fill="#6CC1E6"
      />
      <path
        d="M14.1041 20.1368C14.5105 20.1368 14.7584 20.0305 14.8476 19.8181C14.9368 19.5967 14.9814 19.309 14.9814 18.9548V16.7503C14.9814 16.591 14.9864 16.4095 14.9963 16.2058C15.0062 15.9934 15.0211 15.7897 15.0409 15.595C14.8823 15.6038 14.6939 15.6127 14.4758 15.6215C14.2677 15.6215 14.1091 15.6259 14 15.6348V14.6255C14.684 14.6255 15.2094 14.5812 15.5762 14.4927C15.9529 14.4042 16.1908 14.3156 16.29 14.2271H17.0929V18.8619C17.0929 18.9858 17.088 19.1629 17.0781 19.3931C17.0682 19.6144 17.0533 19.8269 17.0335 20.0305C17.1921 20.0128 17.3656 20.004 17.5539 20.004C17.7522 19.9952 17.9009 19.9907 18 19.9907V21H14.1041V20.1368ZM15.9628 13.178C15.6258 13.178 15.3482 13.0717 15.1301 12.8592C14.912 12.6467 14.803 12.39 14.803 12.089C14.803 11.788 14.917 11.5312 15.145 11.3187C15.373 11.1062 15.6406 11 15.948 11C16.2652 11 16.5279 11.1062 16.7361 11.3187C16.9541 11.5312 17.0632 11.788 17.0632 12.089C17.0632 12.39 16.9641 12.6467 16.7658 12.8592C16.5675 13.0717 16.2999 13.178 15.9628 13.178Z"
        fill="white"
      />
    </svg>
  );
}
