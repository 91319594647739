import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { InputContainer, InputNameLabel } from "./shared-components/Input";

interface StyledSwitchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isChecked?: boolean;
  color: "primary" | "secondary" | "link";
}

const BACKGROUND_OPACITY = "77";

const SwitchContainer = styled(InputContainer)`
  flex: 0 0 1%;
  text-align: left;
  margin-bottom: 1rem;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    flex: 0 0 50%;
    align-self: center;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    margin-bottom: 2rem;
  }
`;

const Checkbox = styled.input<StyledSwitchProps>`
  appearance: none;
  &:focus {
    outline: none;
  }

  display: inline-block;
  position: relative;
  height: 18px;
  width: 39px;
  margin: 0;
  margin-left: 0.3rem;
  background-color: ${(p) => p.theme.components.input.background};
  border-radius: 1rem;
  transition: all 0.2s linear;
  cursor: pointer;
  vertical-align: middle;
  ${(p) => p.disabled && "cursor: default;"}

  &:after {
    content: "";
    position: absolute;
    left: -1px;
    top: -1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.palette[p.color]};
    transition: all 0.2s linear;

    ${(p) => p.disabled && "opacity: 0.4;"}
  }

  &:checked {
    background-color: ${(p) => p.theme.palette[p.color] + BACKGROUND_OPACITY};

    &:after {
      transform: translateX(21px);
    }
  }
`;

const SwitchNameLabel = styled(InputNameLabel)`
  display: inline-block;
  margin: 0;
  margin-right: 0.5rem;
`;

const Switch = forwardRef<HTMLInputElement, StyledSwitchProps>(
  ({isChecked, ...props}, ref) => {
    const [checked, setChecked] = useState(isChecked || false);

    const handleOnClick = () => {
      setChecked(!checked);
    };

    return (
      <SwitchContainer >
        {props.label && (
          <SwitchNameLabel htmlFor={props.id}>{props.label}</SwitchNameLabel>
        )}
        <Checkbox type="checkbox" checked={checked} onClick={handleOnClick} {...props} ref={ref} />
      </SwitchContainer>
    );
  }
);

Switch.defaultProps = {
  color: "primary",
};

export default Switch;
