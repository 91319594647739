export const PROTOCOLS = "^(wss://|ws://|mqtt://|mqtts://)?";

const MQTT_BROKER_URL_KEY = "mqtt-broker-url";
const MQTT_BROKER_PORT_KEY = "mqtt-broker-port";
const MQTT_TOPIC_KEY = "mqtt-topic";
const MQTT_BASE_TOPIC_KEY = "mqtt-base-topic";
const LED_CONTROL_KEY = "led-control";
const MQTT_BROKER_USERNAME_KEY = "mqtt-broker-username";

const MQTT_TOPIC_PREFIX = "/sauter/viasens";
const MQTT_DEFAULT_URL = "wss://broker.emqx.io";
const MQTT_DEFAULT_PORT = "8084";

const LocalStorage = {
  getMqttURL: (): string => {
    const protocol = "ws://";
    const url = localStorage.getItem(MQTT_BROKER_URL_KEY) || MQTT_DEFAULT_URL;
    const match = url.match(new RegExp(PROTOCOLS, "gi"));
    if (match !== null && match[0] !== "") return url;
    else return url.padStart(url.length + protocol.length, protocol);
  },
  setMqttURL: (url: string): void =>
    localStorage.setItem(MQTT_BROKER_URL_KEY, url),

  getMqttPort: (): string =>
    localStorage.getItem(MQTT_BROKER_PORT_KEY) || MQTT_DEFAULT_PORT,
  setMqttPort: (port: string): void =>
    localStorage.setItem(MQTT_BROKER_PORT_KEY, String(port)),

  getMqttTopic: (): string =>
    localStorage.getItem(MQTT_TOPIC_KEY) || MQTT_TOPIC_PREFIX + "/+/+",
  getMqttBaseTopic: (): string =>
    localStorage.getItem(MQTT_BASE_TOPIC_KEY) || MQTT_TOPIC_PREFIX,
  setMqttTopic: (topic: string): void => {
    localStorage.setItem(MQTT_TOPIC_KEY, topic);
    const baseTopic = topic.substring(0, topic.length - 4);
    localStorage.setItem(MQTT_BASE_TOPIC_KEY, baseTopic);
  },

  getLedControl: (): string | null => localStorage.getItem(LED_CONTROL_KEY),
  setLedControl: (control: string): void =>
    localStorage.setItem(LED_CONTROL_KEY, control),

  getMqttUsername: (): string =>
    localStorage.getItem(MQTT_BROKER_USERNAME_KEY) || "",
  setMqttUsername: (username: string): void =>
    localStorage.setItem(MQTT_BROKER_USERNAME_KEY, username),
};

export default LocalStorage;
