import React from "react";
import Select, { GroupBase, Props, Theme } from "react-select";
import styled from "styled-components";
import { InputNameLabel } from "./shared-components/Input";
import Creatable from "react-select/creatable";
import { CreatableAdditionalProps } from "react-select/dist/declarations/src/useCreatable";

interface SelectProps {
  createable?: boolean;
  label?: string;
  disabled?: boolean;
  invalid?: boolean;
  feedbackText?: string;
}

// This container overrides react-select styles, for more custom styles check here https://react-select.com/styles
const SelectContainer = styled.div`
  .react-select {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .select__value-container {
    padding-left: 0.8rem;
  }

  .select__input-container {
    padding: 0;
    margin: 0 2px;
  }

  .select__control {
    border: none;
    background-color: #dcdcdc4f;
    border-radius: 0.3rem;
    font-size: 1.3rem;
    min-height: 30px;

    &:disabled {
      opacity: 0.6;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__indicator {
    padding: 4px;
  }

  .select__single-value {
    text-overflow: ellipsis;
  }

  .select__menu {
    min-width: 100%;
    width: auto;
  }

  .select__option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SelectLabel = styled(InputNameLabel)`
  margin: 0 1rem;
  padding-bottom: 0.5rem;
`;

function MySelect<
  OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  createable = false,
  ...props
}: Props<OptionType, IsMulti, Group> & SelectProps & CreatableAdditionalProps<OptionType, Group>): React.ReactElement {
  return (
    <SelectContainer>
      {props.label && <SelectLabel htmlFor={props.label}>{props.label}</SelectLabel>}
      {createable ? (
        <Creatable
          menuPosition="fixed"
          className="select"
          classNamePrefix="select"
          {...props}
          theme={(theme: Theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "black",
              primary25: theme.colors.neutral20,
            },
          })}
        />
      ) : (
        <Select
          menuPosition="fixed"
          className="select"
          classNamePrefix="select"
          {...props}
          theme={(theme: Theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "black",
              primary25: theme.colors.neutral20,
            },
          })}
        />
      )}
    </SelectContainer>
  );
}

export { MySelect as Select };
