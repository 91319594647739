import React, { useEffect, useRef, useState, RefObject, FormEvent } from "react";
import { useSensorData } from "../context/SensorDataContext";
import styled from "styled-components";
import Button from "./shared-components/Button";
import useDynamicRefs from "use-dynamic-refs";
import Switch from "./Switch";

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1;

  & button {
    margin: 0 1rem;
  }
`;

const NodeList = () => {
  const { nodeAddresses, showLine, hideLine } = useSensorData();
  const [getRef, setRef] = useDynamicRefs();
  const [list, setList] = useState<React.ReactElement[]>([]);

  useEffect(() => {
    const elements = [...nodeAddresses].sort().map((node) => {
      return (
        <Switch
          color={"primary"}
          key={node}
          label={`Node ${node}`}
          name={`node${node}`}
          placeholder={String(node)}
          isChecked={true}
          onChange={onChangeSwitch}
          ref={setRef(String(node)) as RefObject<HTMLInputElement>}
        />
      );
    });

    setList([...elements]);
  }, [nodeAddresses]);

  const onChangeSwitch = ({ currentTarget: { placeholder, checked } }: FormEvent<HTMLInputElement>) => {
    if (checked) {
      showLine(Number(placeholder));
    } else {
      hideLine(Number(placeholder));
    }
  };

  return (
    <>
      <List>
        <Button
          label="Show All"
          color={"secondary"}
          onClick={() => {
            nodeAddresses.forEach((number) => {
              const ref = getRef(String(number)) as RefObject<HTMLInputElement>;
              if (!ref.current?.checked) {
                ref.current?.click();
              }
            });
          }}
        />
        <Button
          label="Hide All"
          color={"secondary"}
          onClick={() => {
            nodeAddresses.forEach((number) => {
              const ref = getRef(String(number)) as RefObject<HTMLInputElement>;
              if (ref.current?.checked) {
                ref.current?.click();
              }
            });
          }}
        />
        {list}
      </List>
    </>
  );
};

export default NodeList;
