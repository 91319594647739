import React from "react";
import { toast, TypeOptions } from "react-toastify";
import styled from "styled-components";

interface IToast {
  type: TypeOptions;
  text: string;
  autoClose?: number | false;
}

const Container = styled.div`
  display: flex;
  position: relative;
  font-family: ${(p) => p.theme.globalStyling.fontFamily};

  & h4 {
    margin: 0;
    margin-bottom: 0.1rem;
    letter-spacing: 1px;
  }

  & p {
    margin: 0;
    font-size: 0.9rem;
  }

  & svg {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    flex-shrink: 0;
    margin-left: -0.2rem;
    margin-right: 0.7rem;
    margin-bottom: 0.3rem;
  }
`;

const Background = styled.div<{ type: TypeOptions | "danger" | "primary" }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  background: ${(p) => p.theme.palette[p.type]};
  opacity: 0.18;
`;

export default function Toast({type, text, autoClose}: IToast): void {
  switch (type) {
    case "default":
      toast(
        <>
          <Background type="primary" />
          <Container>
            <div>
              <p>{text}</p>
            </div>
          </Container>
        </>,
        { autoClose }
      );
      break;
    case "error":
      toast.error(
        <>
          <Background type="danger" />
          <Container>
            <div>
              <h4>Error!</h4>
              <p>{text}</p>
            </div>
          </Container>
        </>,
        { autoClose }
      );
      break;
    case "info":
      toast.info(
        <>
          <Background type="info" />
          <Container>
            <div>
              <h4>Info</h4>
              <p>{text}</p>
            </div>
          </Container>
        </>,
        { autoClose }
      );
      break;
    default:
      toast[type](
        <>
          <Background type={type} />
          <Container>
            <div>
              <p>{text}</p>
            </div>
          </Container>
        </>,
        { autoClose }
      );
  }
}

export const Dismiss = () => toast.dismiss();
