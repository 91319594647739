import React from "react";
import { useHistory } from "react-router";
import Button from "../components/shared-components/Button";
import Container from "../components/shared-components/Container";


const NotFound = () => {
  const history = useHistory();
  
  const handleBackButtonClick = () => history.goBack();
  
  return (
    <Container>
      <h2>This is not the page you&apos;re looking for.</h2>
      <Button label={"GO BACK"} color="primary" onClick={handleBackButtonClick} />
    </Container>
  );
};

export default NotFound;
