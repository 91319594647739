import React, { SyntheticEvent } from "react";
import { MQTT_STATUS, ROUTES } from "../../constants/constants";
import styled from "styled-components";
import NavCard from "../NavCard";
import { Header, Sidebar as SidebarContainer } from "../Sidebar";
import LinkBarContainer from "../LinkBar";
import Label from "../Logo";
import { useSensorData } from "../../context/SensorDataContext";
import { Select } from "../Select";
import { useMqttState } from "mqtt-react-hooks";

const LinkBar = styled(LinkBarContainer)`
  flex-direction: column;
  justify-content: flex-end;

  flex-grow: 1;

  & div {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    display: none;

    & div {
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 1rem;
      margin-bottom: 0.3rem;
    }

    & a {
      margin-bottom: 0.3rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    display: none;
  }
`;

const LogoLabel = styled(Label)`
  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    display: none;
  }
`;

const SidebarSelect = styled(Select)`
  .react-select {
    padding: 0 0.5rem;
    margin-bottom: 0rem;
    font-size: 0.9rem;
  }

  .select__control {
    font-size: 0.9rem;
    flex-wrap: nowrap;
  }

  .select__indicator {
    width: 8px;
    @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
      display: none;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__value-container {
    white-space: nowrap;
  }

  .select__menu-list {
    overflow-x: hidden;
    font-size: 0.9rem;
  }

  & svg {
    margin: 0;
    position: absolute;
    right: 0.2rem;
    top: 0.3rem;
  }
`;

const Status = styled.p<{ status: string | Error }>`
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  color: ${(p) => (p.status === MQTT_STATUS.connected ? p.theme.palette.success : p.theme.palette.gray)};

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    display: none;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    display: none;
  }
`;

function renderStatus(status: string | Error): React.ReactElement {
  return <Status status={status}>{status === MQTT_STATUS.connected ? "CONNECTED" : "NOT CONNECTED"}</Status>;
}

const renderNavItems = (mqttStatus: string | Error) =>
  Object.keys(ROUTES).map((key, index) => {
    const route = ROUTES[key as keyof typeof ROUTES];

    if (route.icon && (route.href || route.onClick)) {
      if (route.label === ROUTES.mqtt.label) {
        return NavItem(route.label, route.icon, index, route.href, route.onClick, renderStatus(mqttStatus));
      }
      return NavItem(route.label, route.icon, index, route.href, route.onClick);
    }
  });

export default function Sidebar(): React.ReactElement {
  const { selectedNetID, netIDsOptions, handleSetNetId } = useSensorData();
  const { connectionStatus } = useMqttState();

  return (
    <SidebarContainer>
      <Header>
        <LogoLabel smallImage className="logo-label" />
      </Header>
      <NavCard
        label={ROUTES.netID.label}
        icon={ROUTES.netID.icon}
        additionalContent={
          <SidebarSelect<React.ElementType>
            placeholder="-"
            label=""
            name="networkID"
            value={selectedNetID}
            options={netIDsOptions}
            onChange={handleSetNetId}
          />
        }
      />
      {renderNavItems(connectionStatus)}

      <LinkBar className="link-bar" />
    </SidebarContainer>
  );
}

function NavItem(
  label: string,
  icon: React.ReactElement,
  key: number,
  href?: string,
  onClick?: React.EventHandler<SyntheticEvent<any, Event>>,
  additionalContent?: React.ReactElement
): React.ReactElement {
  return (
    <NavCard
      label={label}
      href={href}
      onClick={onClick ?? undefined}
      color={"primary"}
      icon={icon}
      key={key}
      additionalContent={additionalContent}
    />
  );
}
