import React, { useMemo } from "react";
import styled from "styled-components";
import { Table, Column, HeaderCell, Cell } from "rsuite-table";
import "rsuite-table/dist/css/rsuite-table.css";
import { useSensorData } from "../context/SensorDataContext";

const StatusContainer = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  width: 36vw;
  margin: auto;
  margin: 0 auto;
  margin-top: 6rem;
  padding: 2rem;
  border-radius: 1.5rem;

  & h2 {
    margin-top: 0;
    text-align: center;
  }

  & h4 {
    margin: 0.2rem 0;
    opacity: 0.75;
  }

  .rs-table {
    border-radius: 0.5rem;
    margin: 1rem;
    z-index: 0;
  }

  .rs-table-row-header {
    background: #eaeaea;
    font-weight: bold;
  }

  .rs-table-row-header .rs-table-cell {
    background: #eaeaea;
  }
`;

const columns = [
  {
    key: "address",
    label: "Address",
    width: 100,
  },
  {
    key: "fw-version",
    label: "Version",
    width: 130,
  },
  {
    key: "ffid",
    label: "FFID",
    width: 130,
  },
  {
    key: "serial",
    label: "Serial",
    width: 150,
  },
  {
    key: "boot-number",
    label: "Boot Number",
    width: 120,
    flexGrow: 1,
  },
];

const Status = (): React.ReactElement => {
  const { status, selectedNetID } = useSensorData();

  const data = useMemo(() => {
    return status
      .filter((stat) => stat.netID === selectedNetID?.value)
      .map((stat) => {
        return {
          address: stat.address,
          key: stat.address,
          ...stat.payload,
        };
      })
      .sort((a, b) => a.address - b.address);
  }, [selectedNetID?.value, status]);

  return (
    <StatusContainer>
      <h2>Node status</h2>
      <Table data={data} autoHeight={true} bordered={true} cellBordered={true} rowKey={"key"} virtualized>
        {columns.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Column key={key} {...rest}>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key} />
            </Column>
          );
        })}
      </Table>
    </StatusContainer>
  );
};

export default Status;
