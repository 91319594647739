import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import Skeleton from "./Skeleton";
import { ClosedEye, OpenEye } from "../SvgIcons/EyeIcons";
import { FieldValues, UseFormRegister, RegisterOptions } from "react-hook-form";
import { theme } from "../../constants/theme.config";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  lowerLabel?: string;
  type?: "text" | "email" | "password" | "number";

  disabled?: boolean;
  pending?: boolean;

  valid?: boolean;
  invalid?: boolean;
  errorMessage?: string;
  feedbackText?: React.ReactNode;
  rules?: RegisterOptions;

  checkOnMount?: boolean;

  register?: UseFormRegister<FieldValues>;
}

export const InputContainer = styled.div<InputProps>`
  flex: ${(p) => (p.type === "number" ? "1 0 25%" : "0 0 50%")};
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 0 1rem;

  & p {
    color: ${(p) => p.theme.palette.danger};
    font-size: 0.8rem;

    margin: 0.2rem 0 0 0.3rem;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    flex: 0 0 50%;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    flex: 0 0 100%;
  }
`;

export const InputNameLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  margin-left: 0.1rem;

  opacity: 0.75;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.4px;
  white-space: nowrap;
`;

const InputField = styled.input<InputProps>`
  border: none;
  border-radius: ${(p) => p.theme.components.input.borderRadius};

  background-color: ${(p) => p.theme.components.input.background};

  &:disabled {
    opacity: 0.6;
  }

  box-shadow: ${(p) => p.theme.components.input.boxShadow};
  font-size: 1.3rem;
  width: 100%;
  height: 30px;
  padding-left: 1rem;
  box-sizing: border-box;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    background-color: transparent;
    margin-top: 1px;
  }
`;

const LowerLabel = styled.p`
  color: ${(props) => props.theme.globalStyling.color} !important;

  opacity: 0.65;
`;

const ErrorLabel = styled.p`
  color: ${theme.palette.danger};
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      lowerLabel,
      invalid,
      feedbackText,
      value,
      rules,
      errorMessage,
      onChange,
      pending,
      checkOnMount,
      ...props
    }: InputProps,
    ref
  ) => {
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [invalidInput, setInvalid] = useState(false);
    const [showPass, setShowPass] = useState(props.type === "password" ? false : null);

    const renderText = () => {
      if (invalid) {
        if (invalidInput) return <p>{feedbackMessage}</p>;
        else if (feedbackText) return <p>{feedbackText}</p>;
      } else {
        if (invalidInput) return <p>{feedbackMessage}</p>;
        else return null;
      }
    };

    return (
      <InputContainer type={props.type} {...props}>
        {pending ? (
          <>
            {props.label && <Skeleton height="0.8rem" width="25%" />}
            <Skeleton height={30} />
          </>
        ) : (
          <>
            {props.label && <InputNameLabel htmlFor={props.id}>{props.label}</InputNameLabel>}
            <InputField
              {...props}
              {...(props.register && props.name && rules ? props.register(props.name, rules) : { ref })}
              autoCapitalize={props.autoCapitalize || "off"}
              autoCorrect={props.autoCorrect || "off"}
              value={value}
              onChange={onChange}
              type={showPass === null ? props.type : showPass === true ? "text" : "password"}
            />
            {!pending && !props.disabled && value !== "" && (
              <>
                {!props.disabled && (
                  <>
                    {showPass === false && <ClosedEye onClick={() => setShowPass(true)} />}
                    {showPass === true && <OpenEye onClick={() => setShowPass(false)} />}
                  </>
                )}
              </>
            )}
            {renderText()}
            {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
            {lowerLabel && <LowerLabel>{lowerLabel}</LowerLabel>}
          </>
        )}
      </InputContainer>
    );
  }
);
