import { INetwork } from "../context/SensorDataContext";
import { SingleValue } from "react-select";
import { OptionType } from "../constants/types";

const NET_ID_KEY = "net-id";
const NETWORKS_KEY = "networks";
const MQTT_BROKER_PWD_KEY = "mqtt-broker-pwd";
const NODE_CONFIG_KEY = "node-config";

const SessionStorage = {
  getNetworks: (): INetwork[] => JSON.parse(sessionStorage.getItem(NETWORKS_KEY) || "[]"),
  setNetworks: (networks: INetwork[]): void => {
    sessionStorage.setItem(NETWORKS_KEY, JSON.stringify(networks));
  },

  getSelectedNetId: (): SingleValue<OptionType> | undefined => {
    const selected = sessionStorage.getItem(NET_ID_KEY);
    if (!selected) return undefined;
    else return JSON.parse(selected);
  },
  setSelectedNetId: (netID: SingleValue<OptionType>): void => sessionStorage.setItem(NET_ID_KEY, JSON.stringify(netID)),

  getMqttPassword: (): string => sessionStorage.getItem(MQTT_BROKER_PWD_KEY) || "",
  setMqttPassword: (pwd: string): void => sessionStorage.setItem(MQTT_BROKER_PWD_KEY, pwd),

  getNodeConfig: (): string => sessionStorage.getItem(NODE_CONFIG_KEY) || "",
  setNodeConfig: (config: string): void =>
    sessionStorage.setItem(NODE_CONFIG_KEY, config),
};

export default SessionStorage;
