import { IMessage, useSubscription } from "mqtt-react-hooks";
import React, { useState, createContext, useEffect, useContext } from "react";
import { useIndexedDB } from "react-indexed-db";
import { DB_STORE_NAME } from "../constants/DBConfig";
import LocalStorage from "../utils/LocalStorage";
import { OptionType, SensorData } from "../constants/types";
import SessionStorage from "../utils/SessionStorage";
import { MQTT_TOPIC_SUFFIX_PRESENCE, MQTT_TOPIC_SUFFIX_STATUS, MQTT_TOPIC_SUFFIX_UPDATE } from "../constants/constants";
import { SingleValue } from "react-select";

export interface INetwork {
  id: number;
  nodes: number[];
}

export interface IStatusData {
  netID: number;
  address: number;
  type: number | string;
  opc: number;
  payload: IStatusPayload;
}

export interface IStatusPayload {
  fw_version: string;
  ffid: string;
  serial: number;
  boot_number: number;
}

export interface ISensorDataContext {
  selectedNetID: SingleValue<OptionType> | undefined;
  nodeAddresses: number[];
  netIDsOptions: OptionType[];
  addressOptions: OptionType[];
  latestData: SensorData | null;
  newNode: boolean;
  linesToShow: number[];
  status: IStatusData[];
}

export interface ISensorDataMethods {
  showLine(address: number): void;
  hideLine(address: number): void;
  handleSetNetId: (netID: SingleValue<OptionType>) => void;
}

interface ISensorDataProvider {
  children?: React.ReactNode;
}

export const initalSensorData: ISensorDataContext = {
  nodeAddresses: [],
  netIDsOptions: [],
  addressOptions: [],
  latestData: null,
  newNode: false,
  linesToShow: [],
  selectedNetID: undefined,
  status: [],
};

export interface ISensorData extends ISensorDataContext, ISensorDataMethods {}

export default function SensorDataProvider({ children }: ISensorDataProvider): React.ReactElement {
  const SUBSCRIBE_TOPICS = [LocalStorage.getMqttTopic(), LocalStorage.getMqttTopic() + "/" + MQTT_TOPIC_SUFFIX_STATUS];
  const { add } = useIndexedDB(DB_STORE_NAME);
  const { message } = useSubscription(SUBSCRIBE_TOPICS);
  const [nodeAddresses, setNodeAddresses] = useState<number[]>([]);
  const [latestData, setLatestData] = useState<SensorData | null>(null);
  const [newNode, setNewNode] = useState<boolean>(false);
  const [linesToShow, setLinesToShow] = useState<number[]>([]);
  const [networks, setNetworks] = useState<INetwork[]>(SessionStorage.getNetworks());
  const [status, setStatus] = React.useState<IStatusData[]>([]);
  const [selectedNetID, setSelectedNetID] = useState<SingleValue<OptionType> | undefined>(
    SessionStorage.getSelectedNetId()
  );

  const TOPIC_SUFFIXES = [MQTT_TOPIC_SUFFIX_PRESENCE, MQTT_TOPIC_SUFFIX_UPDATE];

  const netIDsOptions = React.useMemo(() => {
    return networks
      .sort((a, b) => a.id - b.id)
      .map((net) => {
        return { value: net.id, label: net.id.toString() } as OptionType;
      });
  }, [networks]);

  const addressOptions = React.useMemo(() => {
    let network = networks.find((net: INetwork) => net.id === selectedNetID?.value);
    if (!network) network = networks[0];
    if (network)
      return network.nodes.sort().map((node) => {
        return { value: node, label: node.toString() } as OptionType;
      });
    else return [];
  }, [networks, selectedNetID]);

  const handleSetNetId = (netID: SingleValue<OptionType>) => {
    if (netID?.value === selectedNetID?.value) return;
    setSelectedNetID(netID);
    SessionStorage.setSelectedNetId(netID);
    setNodeAddresses([]);
    setLinesToShow([]);
    setLatestData(null);
  };

  const handleNetwork = (netID: number, nodeAddress: number) => {
    const network = networks.find((net) => net.id === netID);

    if (!selectedNetID) handleSetNetId({ value: netID, label: netID.toString() });

    if (network === undefined) {
      const newNetwork: INetwork = { id: netID, nodes: [nodeAddress] };
      setNetworks((prev) => {
        SessionStorage.setNetworks([...prev, newNetwork]);
        return [...prev, newNetwork];
      });
    } else {
      const index = networks.findIndex((net) => net.id === netID);
      const updateNetwork = networks[index];
      if (!updateNetwork.nodes.includes(nodeAddress)) {
        updateNetwork.nodes = [...updateNetwork.nodes, nodeAddress];
        networks[index] = updateNetwork;
        SessionStorage.setNetworks(networks);
        setNetworks(Object.assign([], networks));
      }
    }
  };

  const handleStatusMsg = (message: IMessage) => {
    const data = JSON.parse(message?.message?.toString() || "{}") as IStatusData;
    const messageTopicSegments = message?.topic.split("/");
    const nodeAddress = parseInt(messageTopicSegments[messageTopicSegments.length - 2]);
    const netID = parseInt(messageTopicSegments[messageTopicSegments.length - 3]);

    handleNetwork(netID, nodeAddress);

    data.address = nodeAddress;
    data.netID = netID;

    setStatus((prevData) => {
      const idx = prevData.findIndex((stat) => stat.address === data.address && stat.netID === data.netID);
      if (idx === -1) return [...prevData, data];
      else {
        const update = [...prevData];
        update[idx] = data;
        return update;
      }
    });
  };

  const handleSensorDataMsg = (message: IMessage) => {
    const data = JSON.parse(message?.message?.toString() || "{}") as SensorData;

    const messageTopicSegments = message?.topic.split("/");
    const nodeAddress = parseInt(messageTopicSegments[messageTopicSegments.length - 1]);
    const netID = parseInt(messageTopicSegments[messageTopicSegments.length - 2]);

    handleNetwork(netID, nodeAddress);

    data.address = nodeAddress;
    data.netID = netID;

    const payload = Object.entries(data.payload);
    if (typeof payload[0][1] !== "number") throw new Error(`Invalid payload ${JSON.stringify(data.payload)}`);

    add({
      data,
      timestamp: new Date().toJSON(),
    }).catch((error) => console.log(error));

    if (selectedNetID !== undefined && selectedNetID?.value !== netID) return;

    if (!nodeAddresses.includes(nodeAddress)) {
      setNodeAddresses([...nodeAddresses, nodeAddress]);
      setNewNode(true);
      setLinesToShow((prevLines) => [...prevLines, nodeAddress]);
    }

    setLatestData(data);
  };

  useEffect(() => {
    if (typeof message === "undefined") return;

    setNewNode(false);
    try {
      const messageTopicSegments = message?.topic.split("/");
      const topicSuffix = messageTopicSegments[messageTopicSegments.length - 1];

      if (TOPIC_SUFFIXES.includes(topicSuffix)) return;

      if (topicSuffix === MQTT_TOPIC_SUFFIX_STATUS) {
        handleStatusMsg(message);
      } else {
        handleSensorDataMsg(message);
      }
    } catch (error) {
      console.error(error);
    }
  }, [message]);

  const showLine = (address: number) => {
    setLinesToShow((prevLines) => [...prevLines, address]);
  };

  const hideLine = (address: number) => {
    setLinesToShow((prevLines) => {
      const update = [...prevLines];
      update.splice(prevLines.indexOf(address), 1);
      return update;
    });
  };

  return (
    <SensorDataContext.Provider
      value={{
        nodeAddresses,
        netIDsOptions,
        addressOptions,
        latestData,
        newNode,
        linesToShow,
        selectedNetID,
        status,
        handleSetNetId,
        showLine,
        hideLine,
      }}
    >
      {children}
    </SensorDataContext.Provider>
  );
}

export const SensorDataContext = createContext(initalSensorData as ISensorData);

export const useSensorData = (): ISensorData => useContext(SensorDataContext);
