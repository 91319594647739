import Chart from "./Chart";
import { dataTypes, payloadKeys } from "../constants/smartSensorDataTypes";
import { FC, useEffect, useState } from "react";
import { useSensorData } from "../context/SensorDataContext";
import { SensorData } from "../constants/types";
import React from "react";

interface IMqttChart {
  title: string;
  type: string;
}

const MqttChart: FC<IMqttChart> = ({ title, type }: IMqttChart) => {
  const [chartData, setChartData] = useState<SensorData | null>(null);
  const { latestData, newNode, selectedNetID } = useSensorData();

  useEffect(() => {
    setChartData(null);
  }, [selectedNetID]);

  useEffect(() => {
    if (latestData) {
      if (dataTypes[type].includes(latestData.type ?? -1)) {
        setChartData(latestData);
      } else if (newNode) {
        const payload = Object.fromEntries(Object.values(payloadKeys[type]).map((key) => [key, 0]));
        setChartData({ ...latestData, payload: { ...payload } });
      }
    }
  }, [latestData]);

  return <Chart title={title} samples={50} data={chartData} />;
};

export default MqttChart;
