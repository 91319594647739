import React, { FC, MouseEvent } from "react";
import { theme } from "../constants/theme.config";
import styled from "styled-components";
import FileIcon from "./SvgIcons/FileIcon";
import RemoveIcon from "./SvgIcons/RemoveIcon";

interface FileItemProps {
  file: File;
  onRemoveItem(): void;
}

const FileItemContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FileImage = styled.div`
  position: relative;
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 0rem;
`;

const FileName = styled.div`
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1rem;
  letter-spacing: 0.05rem;
  color: ${theme.palette.gray};
`;

const FileItem: FC<FileItemProps> = ({ file, onRemoveItem }: FileItemProps) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onRemoveItem();
  };

  return (
    <FileItemContainer>
      <FileImage>
        <FileIcon width={85} height={80} />

        <RemoveIconContainer onClick={handleClick}>
          <RemoveIcon width={20} height={20} />
        </RemoveIconContainer>
      </FileImage>
      <FileName>{file.name}</FileName>
    </FileItemContainer>
  );
};

export default FileItem;
