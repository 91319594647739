import React, { useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import styled from "styled-components";
import { Select } from "../components/Select";
import Button from "../components/shared-components/Button";
import Toast from "../components/Toast";
import { DB_STORE_NAME } from "../constants/DBConfig";
import { useSensorData } from "../context/SensorDataContext";
import { OptionType } from "../constants/types";
import { MultiValue } from "react-select";

const ALLNODES = -1;

const LogContainer = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  width: 20%;
  margin: auto;
  margin: 0 auto;
  margin-top: 10rem;
  padding: 1rem 2rem;
  border-radius: 1.5rem;

  & h2 {
    text-align: center;
  }

  & h4 {
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
    opacity: 0.75;
  }

  & button {
    margin-top: 0;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 65%;
    padding: 1.6rem 1rem;

    & form {
      justify-content: left;
    }

    & img {
      display: inline;
    }
  }
`;

const Log = (): React.ReactElement => {
  const { getAll } = useIndexedDB(DB_STORE_NAME);
  const [nodes, setNodes] = useState<ReadonlyArray<OptionType>>([]);
  const { addressOptions } = useSensorData();
  const logOptions = [{ value: ALLNODES, label: "All nodes" }, ...addressOptions];

  const getLog = async () => {
    const allData = await getAll();
    const values = nodes.map((node) => node.value);
    const logData = values.includes(ALLNODES) ? allData : allData.filter((data) => values.includes(data.data.address));
    const file = new Blob([JSON.stringify(logData, null, 2)], { type: "text/plain" });

    const tempLink = document.createElement("a");
    tempLink.href = URL.createObjectURL(file);
    tempLink.download = "Sensor_data.log";
    tempLink.click();
    URL.revokeObjectURL(tempLink.href);
    Toast({ type: "success", text: "Downloading" });
  };

  useEffect(() => {
    setNodes([]);
  }, [addressOptions]);

  const onChangeSelect = (option: MultiValue<OptionType>) => {
    setNodes(option.map((select) => select));
  };

  return (
    <LogContainer>
      <h2>Log Download</h2>
      <Select
        label="Select nodes"
        className="react-select"
        closeMenuOnSelect={false}
        value={nodes}
        options={logOptions}
        isMulti={true}
        onChange={onChangeSelect}
      />
      <Button label="Download" color="primary" onClick={getLog} disabled={nodes.length === 0} />
    </LogContainer>
  );
};

export default Log;
