import { useMqttState } from "mqtt-react-hooks";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Input } from "../components/shared-components/Input";
import { useSensorData } from "../context/SensorDataContext";
import Button from "../components/shared-components/Button";
import LocalStorage from "../utils/LocalStorage";
import { useForm, SubmitHandler } from "react-hook-form";
import { Select } from "../components/Select";
import { ActionMeta, SingleValue } from "react-select";
import { OptionType } from "../constants/types";
import Toast from "../components/Toast";
import { MQTT_STATUS } from "../constants/constants";
import SessionStorage from "../utils/SessionStorage";
import {
  SensorKey,
  ParameterKeyNumber,
  CONFIGURATION_LIMITS,
  NodeFormData,
  DropDownOptions,
  ParameterKeyName,
  SensorName,
  SensorKeyType,
  ParameterKeyOption,
  ParameterNumberType,
  ParameterOptionType,
} from "../constants/smartSensorDataTypes";

const FormContainer = styled.div`
  background-color: ${(p) => p.theme.components.card.background};
  display: flex;
  flex: 1;
  width: 76%;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  overflow: hidden;

  & h2 {
    text-align: center;
  }

  & h3 {
    padding: 0.3rem 1rem;
    font-size: 1rem;
    font-weight: bolder;
    margin: 0 auto;
    opacity: 0.75;
  }

  & h4 {
    margin: 0 1rem;
    opacity: 0.75;
  }

  & form {
    flex: 1;
  }

  & hr {
    margin: 1rem;
    border-color: ${(p) => p.theme.palette.white};
  }

  & button {
    width: -webkit-fill-available;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    width: 80%;
  }
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  width: 16.666667%;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    width: 100%;
  }
`;

const initialNodeFormState: NodeFormData = {
  [SensorKey.TEMPERATURE]: {
    [ParameterKeyNumber.COV]: 0.2,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
    [ParameterKeyNumber.EMISSIVITY]: 90,
    [ParameterKeyNumber.NTC_OFFSET]: 0,
    [ParameterKeyOption.SOURCE]: DropDownOptions[ParameterKeyOption.SOURCE][0],
  },
  [SensorKey.ILLUMINANCE]: {
    [ParameterKeyNumber.COV]: 50,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
    [ParameterKeyNumber.SLOPE]: 1,
    [ParameterKeyNumber.INTERCEPT]: 0,
  },
  [SensorKey.MICROPHONE]: {
    [ParameterKeyNumber.COV]: 5,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
    [ParameterKeyOption.CEILING_TYPE]: DropDownOptions[ParameterKeyOption.CEILING_TYPE][0],
  },
  [SensorKey.HUMIDITY]: {
    [ParameterKeyNumber.COV]: 5,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
  },
  [SensorKey.VOC]: {
    [ParameterKeyNumber.COV]: 30,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
  },
  [SensorKey.CO2]: {
    [ParameterKeyNumber.COV]: 50,
    [ParameterKeyNumber.REPORT_TIME]: 900,
    [ParameterKeyNumber.DEAD_TIME]: 300,
  },
  [SensorKey.MOTION]: {
    [ParameterKeyNumber.HOLD_TIME]: 30,
    [ParameterKeyOption.SENSITIVITY]: DropDownOptions[ParameterKeyOption.SENSITIVITY][1],
    [ParameterKeyOption.MODE]: DropDownOptions[ParameterKeyOption.MODE][0],
    [ParameterKeyNumber.REPORT_TIME]: 900,
  },
  [SensorKey.IBEACON]: {
    [ParameterKeyOption.TX_POWER]: DropDownOptions[ParameterKeyOption.TX_POWER][4],
  },
};

const Node = (): React.ReactElement => {
  const { addressOptions, selectedNetID } = useSensorData();
  const [nodeForm, setNodeForm] = useState(initialNodeFormState);
  const [address, setAddress] = useState<SingleValue<OptionType>>();
  const { client, connectionStatus } = useMqttState();

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<NodeFormData>({
    defaultValues: {
      ...initialNodeFormState,
    },
    shouldFocusError: true,
  });

  useEffect(() => {
    const prevConfig = SessionStorage.getNodeConfig();
    if (prevConfig) {
      setNodeForm(JSON.parse(prevConfig) as NodeFormData);
    }
  }, []);

  useEffect(() => {
    setAddress(addressOptions[0]);
  }, [addressOptions]);

  const sendConfiguration: SubmitHandler<NodeFormData> = () => {
    if (connectionStatus !== MQTT_STATUS.connected) {
      Toast({ type: "warning", text: "Mqtt not connected" });
      return;
    }

    SessionStorage.setNodeConfig(JSON.stringify(nodeForm));
    const message = {
      type: "sensors-config",
      payload: {
        ...nodeForm,
        temperature: {
          ...nodeForm.temperature,
          source: nodeForm.temperature.source?.value,
        },
        occupancy: {
          ...nodeForm.occupancy,
          sensitivity: nodeForm.occupancy.sensitivity?.value,
          mode: nodeForm.occupancy.mode?.value,
        },
        microphone: {
          ...nodeForm.microphone,
          "ceiling-type": nodeForm.microphone["ceiling-type"]?.value,
        },
        ibeacon: {
          "tx-power": nodeForm.ibeacon["tx-power"]?.value,
        },
      },
    };
    client?.publish(
      `${LocalStorage.getMqttBaseTopic()}/${selectedNetID?.value}/${address?.value}/configure`,
      JSON.stringify(message)
    );
    Toast({ type: "success", text: "Node configuration sent" });
  };

  const onChangeInput = (
    { currentTarget: { value } }: FormEvent<HTMLInputElement>,
    sensorKey: SensorKeyType,
    parameterKey: ParameterNumberType
  ) => {
    setNodeForm({
      ...nodeForm,
      [sensorKey]: {
        ...nodeForm[sensorKey],
        [parameterKey]: Number(value),
      },
    });
    handleValidation(Number(value), sensorKey, parameterKey);
  };

  const onChangeSelect = (option: SingleValue<OptionType>, { name }: ActionMeta<OptionType>, sensor: SensorKeyType) => {
    if (name && option) {
      setNodeForm({
        ...nodeForm,
        [sensor]: {
          ...nodeForm[sensor],
          [name]: option,
        },
      });
    }
  };

  const getLimitsError = (value: number, sensorKey: SensorKeyType, parameterKey: ParameterNumberType) => {
    const zeroAllowedParameters: string[] = [
      ParameterKeyNumber.COV,
      ParameterKeyNumber.REPORT_TIME,
      ParameterKeyNumber.DEAD_TIME,
    ];
    const parameterLimits = CONFIGURATION_LIMITS[sensorKey as SensorKeyType][parameterKey as ParameterNumberType];
    if (parameterLimits) {
      if (parameterLimits.MINIMUM) {
        const isZeroAllowed = zeroAllowedParameters.includes(parameterKey);
        const valueAboveMin = Number(value) >= parameterLimits.MINIMUM;

        // Value has to be greater than minimum value, or if zero is allowed, it can also be zero
        const valueAllowed = isZeroAllowed ? valueAboveMin || Number(value) === 0 : valueAboveMin;
        if (!valueAllowed) {
          return `Minimal value is ${parameterLimits?.MINIMUM}${isZeroAllowed ? " or 0 to disable it" : ""}`;
        }
      }
      if (parameterLimits.MAXIMUM && Number(value) > parameterLimits.MAXIMUM) {
        return `Maximum value is ${parameterLimits?.MAXIMUM}`;
      }
    }
  };

  const getDeadTimeError = (value: number, sensorKey: SensorKeyType, parameterKey: ParameterNumberType) => {
    let errorMessage;
    if (!value) return;
    switch (parameterKey) {
      case ParameterKeyNumber.DEAD_TIME:
        if (
          nodeForm[sensorKey][ParameterKeyNumber.REPORT_TIME] &&
          value > nodeForm[sensorKey][ParameterKeyNumber.REPORT_TIME]!
        ) {
          errorMessage = "Dead time can't be greater than report time";
        } else {
          clearErrors(`${sensorKey as SensorKeyType}.${ParameterKeyNumber.REPORT_TIME}`);
        }
        break;
      case ParameterKeyNumber.REPORT_TIME:
        if (
          nodeForm[sensorKey][ParameterKeyNumber.DEAD_TIME] &&
          value < nodeForm[sensorKey][ParameterKeyNumber.DEAD_TIME]!
        ) {
          errorMessage = "Dead time can't be greater than report time";
        } else {
          clearErrors(`${sensorKey as SensorKeyType}.${ParameterKeyNumber.DEAD_TIME}`);
        }
        break;
    }
    return errorMessage;
  };

  const handleValidation = (value: number, sensorKey: SensorKeyType, parameterKey: ParameterNumberType) => {
    clearErrors(`${sensorKey as SensorKeyType}.${parameterKey as ParameterNumberType}`);

    const limitsError = getLimitsError(value, sensorKey, parameterKey);

    if (limitsError) {
      setError(`${sensorKey as SensorKeyType}.${parameterKey as ParameterNumberType}`, {
        type: "custom",
        message: limitsError,
      });
    } else if (parameterKey === ParameterKeyNumber.DEAD_TIME || parameterKey === ParameterKeyNumber.REPORT_TIME) {
      const deadTimeError = getDeadTimeError(value, sensorKey, parameterKey);
      if (deadTimeError) {
        setError(`${sensorKey as SensorKeyType}.${parameterKey as ParameterNumberType}`, {
          type: "custom",
          message: deadTimeError,
        });
      }
    }
  };

  const focusNextInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let indexOfNext = 0;
    const currentElement = inputRefs.current.find((ref) => document.activeElement === ref);
    if (currentElement) indexOfNext = inputRefs.current.indexOf(currentElement) + 1;
    if (indexOfNext < inputRefs.current.length) {
      inputRefs.current[indexOfNext].focus();
    } else {
      inputRefs.current[0].focus();
    }
  };

  const addInputRef = (ref: HTMLInputElement | null) => {
    if (ref && !inputRefs.current.includes(ref)) {
      inputRefs.current[inputRefs.current.length] = ref;
    }
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(sendConfiguration)}>
        <h2>Node Configuration</h2>
        <Wrapper>
          <Select
            label="Address"
            className="react-select"
            defaultValue={address}
            value={address}
            options={addressOptions}
            onChange={(selected) => setAddress(selected)}
          />
        </Wrapper>
        <hr />
        {Object.entries(initialNodeFormState).map(([sensorKey, sensorValue], index) => {
          return (
            <React.Fragment key={index}>
              <h3>{SensorName[sensorKey as SensorKeyType]}</h3>
              <Row>
                {Object.entries(sensorValue).map(([parameterKey, parameterValue], index) => {
                  if (Object.values(ParameterKeyNumber).includes(parameterKey as ParameterNumberType)) {
                    return (
                      <Wrapper key={index}>
                        <Input
                          label={ParameterKeyName[parameterKey as ParameterNumberType]}
                          name={`${parameterKey}`}
                          type={"number"}
                          ref={(input) => {
                            addInputRef(input);
                          }}
                          errorMessage={
                            errors[sensorKey as SensorKeyType]?.[parameterKey as ParameterNumberType]?.message
                          }
                          value={nodeForm[sensorKey as SensorKeyType][parameterKey as ParameterNumberType]}
                          onChange={(e) =>
                            onChangeInput(e, sensorKey as SensorKeyType, parameterKey as ParameterNumberType)
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") focusNextInput(e);
                          }}
                        />
                      </Wrapper>
                    );
                  } else {
                    return (
                      <Wrapper key={index}>
                        <Select
                          label={ParameterKeyName[parameterKey as ParameterOptionType]}
                          name={parameterKey}
                          className="react-select"
                          value={nodeForm[sensorKey as SensorKeyType][parameterKey as ParameterOptionType]}
                          options={DropDownOptions[parameterKey as ParameterOptionType]}
                          onChange={(newValue, actionMeta) =>
                            onChangeSelect(newValue, actionMeta, sensorKey as SensorKeyType)
                          }
                        />
                      </Wrapper>
                    );
                  }
                })}
              </Row>
            </React.Fragment>
          );
        })}
        <Wrapper>
          <Button label="Send" color="primary" type="submit" disabled={!address} />
        </Wrapper>
      </form>
    </FormContainer>
  );
};

export default Node;
