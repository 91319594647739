import styled from "styled-components";
import { Tabs, Tab, TabList, TabPanel, TabsProps } from "react-tabs";

const StyledTabs = styled(Tabs)<TabsProps>`
  .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    padding: 0rem 1rem;
    flex-wrap: nowrap;
    border-bottom: 1px solid lightgray;
    margin: 0rem 1rem;
    margin-bottom: 2rem;
  }

  .react-tabs__tab {
    display: inline-block;
    position: relative;
    list-style: none;
    padding: 0.4rem 1rem;
    margin: 0.2rem 1rem;
    cursor: pointer;
    color: gray;
    bottom: -5px;
    font-size: 1.2rem;

    &:focus-visible {
      outline: none;
    }
  }

  .react-tabs__tab--selected {
    color: black;
    font-weight: bold;
    border-bottom: 3px solid black;
  }

  .react-tabs__tab--disabled {
    color: lightgray;
    cursor: default;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .react-tabs__tab-panel--selected {
    animation-name: fadeIn;
    animation-duration: 1s;
  }
`;

export { StyledTabs as Tabs, Tab, TabList, TabPanel };
