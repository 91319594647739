import React, { PropsWithChildren } from "react";
import {
  ToastContainer as DefaultToastContainer,
  ToastContainerProps,
} from "react-toastify";
import styled from "styled-components";

const COLORS_MAPPING = {
  primary: "default",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "error",
};

const Toast = styled(DefaultToastContainer)`
  .Toastify__toast {
    border-radius: ${(p) => p.theme.components.card.borderRadius};
    background: ${(p) => p.theme.palette.white};
    overflow-wrap: anywhere;
  }

  ${(p) =>
    Object.keys(COLORS_MAPPING).map(
      (key: string) =>
        ` .Toastify__toast--${
          COLORS_MAPPING[key as keyof typeof COLORS_MAPPING]
        } {
          color: ${p.theme.palette[key]};
          border: 1px solid ${p.theme.palette[key]};

          .Toastify__progress-bar {
            background: ${p.theme.palette[key]};
          }

          .Toastify__close-button {
            color: ${p.theme.palette[key]};
          }
        }
      `
    )}
`;

export default function ToastContainer(
  props: PropsWithChildren<ToastContainerProps>
): React.ReactElement<ToastContainerProps> {
  return <Toast {...props} />;
}
