import { ButtonHTMLAttributes, forwardRef } from "react";
import styled from "styled-components/macro";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;

  color: "primary" | "secondary" | "link";
}

const ButtonContainer = styled.button<IButton>`
  transition: background-color 0.15s ease-in-out;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: ${(p) => p.theme.components.button[p.color].borderRadius};
  background-color: ${(p) => p.theme.components.button[p.color].background};
  cursor: pointer;
  font-weight: ${(p) => p.theme.components.button[p.color].fontWeight};
  font-size: ${(p) => p.theme.globalStyling.fontSize};
  color: ${(p) => p.theme.components.button[p.color].color};

  &:hover {
    background-color: ${(p) =>
    !p.disabled && p.theme.components.button[p.color].hover};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

`;

const Button = forwardRef<HTMLButtonElement, IButton>(
  ({ label, ...props }, ref) => {
    return (
      <ButtonContainer {...props} label={label} ref={ref}>
        {label}
      </ButtonContainer>
    );
  }
);

Button.displayName = "Button";

export default Button;
