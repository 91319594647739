import React, { FC } from "react";
import styled from "styled-components";
import Container from "./shared-components/Container";
import MqttChart from "./MqttChart";
import NodeList from "./NodeList";

const ChartContainers = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => p.theme.components.card.background};
  border-radius: 1.5rem;
  margin: 0;
  padding: 1rem 0;
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const CanvasContainer = styled.div`
  position: relative;
  width: 36vw;
  height: 30vh;
  padding: 0 1rem;
`;

const ChartContainer: FC = () => {
  return (
    <ChartContainers>
      <NodeList />
      <ChartsContainer>
        <CanvasContainer>
          <MqttChart title={"Humidity"} type={"HUMID"} />
        </CanvasContainer>
        <CanvasContainer>
          <MqttChart title={"Temperature"} type={"TEMP"} />
        </CanvasContainer>
      </ChartsContainer>
      <ChartsContainer>
        <CanvasContainer>
          <MqttChart title={"VOC"} type={"VOC"} />
        </CanvasContainer>
        <CanvasContainer>
          <MqttChart title={"CO2"} type={"CO2"} />
        </CanvasContainer>
      </ChartsContainer>
      <ChartsContainer>
        <CanvasContainer>
          <MqttChart title={"Illuminance"} type={"ILLUMINANCE"} />
        </CanvasContainer>
        <CanvasContainer>
          <MqttChart title={"Mic"} type={"MIC"} />
        </CanvasContainer>
      </ChartsContainer>
      <ChartsContainer>
        <CanvasContainer>
          <MqttChart title={"PIR"} type={"MOTION"} />
        </CanvasContainer>
      </ChartsContainer>
    </ChartContainers>
  );
};

export default ChartContainer;
