import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { BASE_ROUTE } from "../constants/constants";

interface LogoProps extends React.HTMLAttributes<HTMLSpanElement> {
  smallImage: boolean;
}

const Colored = styled.div`
  color: ${(p) => p.theme.palette.info};
`;

const Container = styled.span`
  word-break: normal;

  & span {
    display: inline-block;
    margin-top: 0.3rem;
  }
  & strong {
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    & strong {
      flex-wrap: nowrap;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    & img {
      display: inline;
      max-width: 70%;
      margin-left: 1rem;
    }

    & span {
      font-size: 0.6rem;
      display: block;
      margin: 0.5rem 0 1.3rem 1rem;
    }
  }
`;

const Image = styled.img<
  React.ImgHTMLAttributes<HTMLImageElement> & { smallImage: boolean }
>`
  width: 100%;
  height: auto;
  max-width: 10rem;

  ${(p) =>
    p.smallImage &&
    ` @media screen and (max-width: ${p.theme.mediaQueries.tablet}) {
        width: auto;
      }`}
`;

const SmallImage = styled.source.attrs((p) => ({
  media: `(max-width: ${p.theme.mediaQueries.tablet})`,
}))``;

export default function Logo({
  smallImage,
  ...props
}: LogoProps): React.ReactElement {
  const { pathname } = useLocation();

  return (
    <Container {...props}>
      <picture>
        {smallImage && <SmallImage srcSet="img/sauter-logo-small.png" />}
        <Image
          smallImage={smallImage}
          src={
            pathname.includes(BASE_ROUTE)
              ? "img/sauter-logo.png"
              : `${BASE_ROUTE}/img/sauter-logo.png`
          }
        />
      </picture>
      <br />

      <span>
        <strong>
          <Colored>Smart Sensor</Colored>
          <div>Dashboard</div>
        </strong>
      </span>
    </Container>
  );
}

Logo.defaultProps = {
  smallImage: false,
};
