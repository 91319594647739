import React from "react";

export default function LedRingIcon(): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="10" fill="url(#paint0_radial_10:6)" />
      <circle cx="15.9998" cy="16.32" r="10.24" fill="white" />
      <ellipse cx="16.0001" cy="16.32" rx="6.4" ry="6.4" fill="#E6D16C" />
      <defs>
        <radialGradient
          id="paint0_radial_10:6"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 16) rotate(90) scale(16)"
        >
          <stop offset="0.479167" stopColor="#E6D16C" stopOpacity="0" />
          <stop offset="1" stopColor="#E3CF6A" />
        </radialGradient>
      </defs>
    </svg>
  );
}
