import React from "react";

export default function FileIcon(props: React.SVGAttributes<SVGElement>): React.ReactElement {
  return (
    <svg width="66" height="59" viewBox="0 0 66 59" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M51.4888 5.88148L49.271 5.8896L21.9478 5.98961L19.73 5.99773C18.6532 6.00288 17.6225 6.43325 16.8639 7.19444C16.1053 7.95562 15.6809 8.98544 15.6837 10.058L15.8219 47.2019C15.8271 48.2744 16.2592 49.3011 17.0234 50.0567C17.7876 50.8123 18.8215 51.2352 19.8983 51.2324L19.9041 51.2324L51.6514 51.1162L51.6571 51.1162C52.7339 51.111 53.7646 50.6807 54.5232 49.9195C55.2818 49.1583 55.7062 48.1285 55.7035 47.0559L55.5653 9.912C55.5601 8.83947 55.1279 7.81278 54.3637 7.05717C53.5995 6.30156 52.5656 5.87874 51.4888 5.88148Z"
          fill="#F2F2F2"
        />
        <path
          d="M37.2811 32.6363L21.7296 36.6308C21.5559 36.6753 21.3716 36.6493 21.2172 36.5585C21.0628 36.4677 20.951 36.3196 20.9062 36.1466C20.8614 35.9737 20.8874 35.7901 20.9785 35.6363C21.0695 35.4825 21.2182 35.3709 21.3918 35.3262L36.9434 31.3316C37.1171 31.287 37.3014 31.313 37.4559 31.4038C37.6104 31.4945 37.7224 31.6427 37.7672 31.8157C37.812 31.9888 37.7859 32.1724 37.6948 32.3263C37.6036 32.4802 37.4548 32.5917 37.2811 32.6363Z"
          fill="#F2F2F2"
        />
        <path
          d="M40.5521 34.1439L22.2989 38.8323C22.2129 38.8545 22.1233 38.8596 22.0353 38.8473C21.9473 38.835 21.8626 38.8055 21.7861 38.7605C21.7095 38.7156 21.6426 38.6561 21.5891 38.5853C21.5357 38.5146 21.4967 38.4341 21.4746 38.3484C21.4524 38.2627 21.4473 38.1734 21.4598 38.0858C21.4722 37.9982 21.5019 37.9138 21.5471 37.8376C21.5923 37.7614 21.6521 37.6948 21.7232 37.6416C21.7942 37.5884 21.8751 37.5497 21.9612 37.5277L40.2143 32.8392C40.388 32.7946 40.5723 32.8205 40.7268 32.9113C40.8813 33.0021 40.9933 33.1503 41.0381 33.3233C41.0829 33.4963 41.0568 33.68 40.9657 33.8339C40.8745 33.9877 40.7257 34.0993 40.5521 34.1439Z"
          fill="#F2F2F2"
        />
        <path
          d="M55.2492 9.24585H23.4902C22.4134 9.24706 21.381 9.67366 20.6196 10.4321C19.8582 11.1905 19.4299 12.2187 19.4287 13.2912V50.4353C19.4299 51.5078 19.8582 52.5361 20.6196 53.2945C21.381 54.0529 22.4134 54.4795 23.4902 54.4807H55.2492C56.326 54.4795 57.3584 54.0529 58.1198 53.2945C58.8812 52.5361 59.3095 51.5078 59.3107 50.4353V13.2912C59.3095 12.2187 58.8812 11.1905 58.1198 10.4321C57.3584 9.67366 56.326 9.24706 55.2492 9.24585Z"
          fill="#E6E6E6"
        />
        <path
          d="M55.2491 10.4104H23.4899C22.7231 10.4113 21.988 10.715 21.4457 11.2551C20.9035 11.7952 20.5985 12.5274 20.5977 13.2912V50.4353C20.5985 51.199 20.9035 51.9313 21.4457 52.4714C21.988 53.0114 22.7231 53.3152 23.4899 53.3161H55.2491C56.0159 53.3152 56.751 53.0114 57.2932 52.4714C57.8355 51.9313 58.1405 51.199 58.1413 50.4353V13.2912C58.1405 12.5274 57.8355 11.7952 57.2932 11.2551C56.751 10.7151 56.0159 10.4113 55.2491 10.4104Z"
          fill="white"
        />
        <path
          d="M50.4699 30.821H34.4095C34.3206 30.8211 34.2326 30.8037 34.1505 30.7699C34.0683 30.7361 33.9937 30.6865 33.9308 30.6239C33.8679 30.5614 33.818 30.4871 33.784 30.4053C33.7499 30.3235 33.7324 30.2358 33.7324 30.1473C33.7324 30.0588 33.7499 29.9711 33.784 29.8893C33.818 29.8075 33.8679 29.7332 33.9308 29.6707C33.9937 29.6081 34.0683 29.5585 34.1505 29.5247C34.2326 29.4909 34.3206 29.4735 34.4095 29.4736H50.4699C50.6491 29.4738 50.8209 29.5449 50.9476 29.6712C51.0743 29.7975 51.1454 29.9688 51.1454 30.1473C51.1454 30.3258 51.0743 30.4971 50.9476 30.6234C50.8209 30.7497 50.6491 30.8208 50.4699 30.821Z"
          fill="#E6E6E6"
        />
        <path
          d="M53.2598 33.0946H34.4095C34.3206 33.0947 34.2326 33.0774 34.1505 33.0436C34.0683 33.0098 33.9937 32.9602 33.9308 32.8976C33.8679 32.835 33.818 32.7607 33.784 32.6789C33.7499 32.5972 33.7324 32.5095 33.7324 32.421C33.7324 32.3324 33.7499 32.2448 33.784 32.163C33.818 32.0812 33.8679 32.0069 33.9308 31.9444C33.9937 31.8818 34.0683 31.8322 34.1505 31.7984C34.2326 31.7646 34.3206 31.7472 34.4095 31.7473H53.2598C53.4392 31.7473 53.6112 31.8183 53.738 31.9446C53.8649 32.071 53.9361 32.2423 53.9361 32.421C53.9361 32.5996 53.8649 32.771 53.738 32.8973C53.6112 33.0237 53.4392 33.0946 53.2598 33.0946Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.4699 41.0102H34.4095C34.3206 41.0103 34.2326 40.9929 34.1505 40.9591C34.0683 40.9253 33.9937 40.8757 33.9308 40.8131C33.8679 40.7505 33.818 40.6762 33.784 40.5945C33.7499 40.5127 33.7324 40.425 33.7324 40.3365C33.7324 40.248 33.7499 40.1603 33.784 40.0785C33.818 39.9967 33.8679 39.9224 33.9308 39.8599C33.9937 39.7973 34.0683 39.7477 34.1505 39.7139C34.2326 39.6801 34.3206 39.6627 34.4095 39.6628H50.4699C50.6492 39.6628 50.8213 39.7338 50.9481 39.8602C51.0749 39.9865 51.1462 40.1578 51.1462 40.3365C51.1462 40.5152 51.0749 40.6865 50.9481 40.8128C50.8213 40.9392 50.6492 41.0102 50.4699 41.0102Z"
          fill="#E6E6E6"
        />
        <path
          d="M53.2598 43.2839H34.4095C34.3206 43.284 34.2326 43.2666 34.1505 43.2328C34.0683 43.199 33.9937 43.1494 33.9308 43.0868C33.8679 43.0243 33.818 42.95 33.784 42.8682C33.7499 42.7864 33.7324 42.6987 33.7324 42.6102C33.7324 42.5217 33.7499 42.434 33.784 42.3522C33.818 42.2704 33.8679 42.1961 33.9308 42.1336C33.9937 42.071 34.0683 42.0214 34.1505 41.9876C34.2326 41.9538 34.3206 41.9364 34.4095 41.9365H53.2598C53.3487 41.9364 53.4367 41.9538 53.5189 41.9876C53.601 42.0214 53.6757 42.071 53.7385 42.1336C53.8014 42.1961 53.8513 42.2704 53.8854 42.3522C53.9194 42.434 53.9369 42.5217 53.9369 42.6102C53.9369 42.6987 53.9194 42.7864 53.8854 42.8682C53.8513 42.95 53.8014 43.0243 53.7385 43.0868C53.6757 43.1494 53.601 43.199 53.5189 43.2328C53.4367 43.2666 53.3487 43.284 53.2598 43.2839Z"
          fill="#E6E6E6"
        />
        <path
          d="M30.8116 34.1269H25.128C25.042 34.1268 24.9596 34.0927 24.8988 34.0321C24.838 33.9716 24.8038 33.8895 24.8037 33.8038V28.7647C24.8038 28.679 24.838 28.5969 24.8988 28.5364C24.9596 28.4758 25.042 28.4417 25.128 28.4417H30.8116C30.8976 28.4417 30.98 28.4758 31.0408 28.5364C31.1016 28.5969 31.1358 28.679 31.1359 28.7647V33.8038C31.1358 33.8895 31.1016 33.9716 31.0408 34.0321C30.98 34.0927 30.8976 34.1268 30.8116 34.1269Z"
          fill="#E6E6E6"
        />
        <path
          d="M30.8116 44.3161H25.128C25.042 44.316 24.9596 44.2819 24.8988 44.2214C24.838 44.1608 24.8038 44.0787 24.8037 43.9931V38.9539C24.8038 38.8682 24.838 38.7861 24.8988 38.7256C24.9596 38.665 25.042 38.631 25.128 38.6309H30.8116C30.8976 38.631 30.98 38.665 31.0408 38.7256C31.1016 38.7861 31.1358 38.8682 31.1359 38.9539V43.9931C31.1358 44.0787 31.1016 44.1608 31.0408 44.2214C30.98 44.2819 30.8976 44.316 30.8116 44.3161Z"
          fill="#E6E6E6"
        />
        <path
          d="M50.4877 19.4784H38.647C38.4677 19.4784 38.2956 19.4075 38.1688 19.2811C38.042 19.1548 37.9707 18.9834 37.9707 18.8048C37.9707 18.6261 38.042 18.4548 38.1688 18.3284C38.2956 18.2021 38.4677 18.1311 38.647 18.1311H50.4877C50.6671 18.1311 50.8391 18.2021 50.966 18.3284C51.0928 18.4548 51.1641 18.6261 51.1641 18.8048C51.1641 18.9834 51.0928 19.1548 50.966 19.2811C50.8391 19.4075 50.6671 19.4784 50.4877 19.4784Z"
          fill="#CCCCCC"
        />
        <path
          d="M53.2776 21.7521H38.647C38.5582 21.7521 38.4703 21.7347 38.3882 21.7008C38.3062 21.667 38.2316 21.6174 38.1688 21.5548C38.106 21.4922 38.0562 21.418 38.0222 21.3363C37.9882 21.2545 37.9707 21.1669 37.9707 21.0785C37.9707 20.99 37.9882 20.9024 38.0222 20.8206C38.0562 20.7389 38.106 20.6647 38.1688 20.6021C38.2316 20.5395 38.3062 20.4899 38.3882 20.4561C38.4703 20.4222 38.5582 20.4048 38.647 20.4048H53.2776C53.457 20.4048 53.629 20.4758 53.7559 20.6021C53.8827 20.7284 53.954 20.8998 53.954 21.0785C53.954 21.2571 53.8827 21.4285 53.7559 21.5548C53.629 21.6811 53.457 21.7521 53.2776 21.7521Z"
          fill="#CCCCCC"
        />
        <path
          d="M36.0265 24.5074H25.1104C25.0245 24.5073 24.942 24.4732 24.8812 24.4127C24.8204 24.3521 24.7862 24.27 24.7861 24.1844V15.6988C24.7862 15.6131 24.8204 15.531 24.8812 15.4704C24.942 15.4099 25.0245 15.3758 25.1104 15.3757H36.0265C36.1125 15.3758 36.1949 15.4099 36.2557 15.4704C36.3165 15.531 36.3507 15.6131 36.3508 15.6988V24.1844C36.3507 24.27 36.3165 24.3521 36.2557 24.4127C36.1949 24.4732 36.1125 24.5073 36.0265 24.5074Z"
          fill="#307FE2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="66" height="59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
