/* eslint-disable indent */
import React from "react";
import styled from "styled-components";
import { ROUTES } from "../constants/constants";

export interface NavCardProps extends React.HTMLAttributes<HTMLDivElement> {
  color: "primary" | "secondary" | "link" | string;

  label?: string;

  disabled?: boolean;

  href?: string;

  icon?: React.ReactElement;

  additionalContent?: React.ReactElement;
}

export const AnchorWrapper = styled.a`
  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }

  margin: 0.5rem 0;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    margin: 0;
  }
`;

export const CardContainer = styled.div<NavCardProps & { $active: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  padding: 0.8rem 1.5rem;
  border-radius: ${(p) => p.theme.components.navCard.borderRadius};

  cursor: pointer;
  overflow-x: hidden;

  ${(p) => p.theme.components.navCard.borderColor && "border: 1px solid transparent;"};

  color: black;

  &:hover {
    background-color: ${(p) =>
      !p.$active && (p.theme.components.navCard.hover || p.theme.components.navCard.background)};
    ${(p) => p.$active && `border: 1px solid ${p.theme.components.navCard.borderColor}`}
  }

  ${(p) =>
    p.$active &&
    ` font-weight: bold;
      background-color: ${p.theme.components.navCard.background};
      border: 1px solid ${p.theme.components.navCard.borderColor};`}

  & svg {
    margin-right: 1rem;
    flex-shrink: 0;

    @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
      ${(p) => (p.label === ROUTES.netID.label ? "display: none" : "")};
      margin-right: 0;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    justify-content: center;
  }

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.mobile}) {
    padding: 0.5rem;
  }
`;

export const LinkLabel = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 0.9rem;

  @media screen and (max-width: ${(p) => p.theme.mediaQueries.tablet}) {
    margin-left: 1rem;

    display: none;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  margin-left: auto;
  margin-right: 0;
`;

export function NavCard({ icon: Icon, additionalContent, ...props }: NavCardProps): React.ReactElement {
  const active = props.href ? window.location.pathname.endsWith(props.href) : false;

  return (
    // Links need to be relative due to proxy issues, add dot before href
    <AnchorWrapper href={props.href && `.${props.href}`}>
      <CardContainer $active={active} {...props}>
        {Icon}

        <LinkLabel {...props} className="link-label">
          {props.label}
        </LinkLabel>
        {additionalContent && <RightContainer {...props}>{additionalContent}</RightContainer>}
      </CardContainer>
    </AnchorWrapper>
  );
}

export default NavCard;

NavCard.defaultProps = {
  color: "primary",
};
