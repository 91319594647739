import React from "react";

export default function DocumentationIcon(): React.ReactElement {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4_7)">
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0H22C27.5228 0 32 4.47715 32 10V22C32 27.5228 27.5228 32 22 32H10C4.47715 32 0 27.5228 0 22V10Z"
          fill="#0C9B23"
        />
        <rect
          x="10.6935"
          y="8.04856"
          width="12.9967"
          height="17.9274"
          rx="1.24102"
          stroke="white"
          stroke-width="1.37113"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.62422 6.70958H19.992C20.3393 6.70958 20.6209 6.99113 20.6209 7.33844V7.363H21.992V7.33844C21.992 6.23387 21.0966 5.33844 19.992 5.33844H9.62422C8.51965 5.33844 7.62422 6.23387 7.62422 7.33844V22.637C7.62422 23.7416 8.51965 24.637 9.62422 24.637H10.008V23.2659H9.62422C9.27691 23.2659 8.99536 22.9843 8.99536 22.637V7.33844C8.99536 6.99113 9.27691 6.70958 9.62422 6.70958Z"
          fill="white"
        />
        <path d="M13.2461 15.143L20.5348 15.143" stroke="white" stroke-width="1.37113" stroke-linecap="round" />
        <path d="M13.2461 17.7009L20.5348 17.7009" stroke="white" stroke-width="1.37113" stroke-linecap="round" />
        <path d="M13.2461 20.2588L20.5348 20.2588" stroke="white" stroke-width="1.37113" stroke-linecap="round" />
        <path d="M13.2461 22.8167L20.5348 22.8167" stroke="white" stroke-width="1.37113" stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_4_7">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
