import React, { useState } from "react";
import styled from "styled-components";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDropItem(file: File): void;
}

const FileInputContainer = styled.div<{ drag: boolean }>`
  & span,
  & div {
    transition: ease-in-out 200ms;
  }

  border: dashed;
  border-radius: ${(p) => p.theme.components.highlight.borderRadius};
  margin: 1rem;
  margin-bottom: 1.6rem;

  ${(p) =>
    p.drag
      ? ` 
      & span {
        color:  ${p.theme.components.card.background};
      }
      & div {
        background-color: ${p.theme.palette.primary};
        color: ${p.theme.components.card.background};
    }
    `
      : `
      background-color: ${p.theme.components.card.background};
      color: ${p.theme.components.card.color};`}
`;

export default function FileInputDragDrop({ onDropItem, ...props }: InputProps): React.ReactElement {
  const [drag, setDrag] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) setDrag(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter - 1);
    if (dragCounter === 1) setDrag(false);
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDropItem(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  return (
    <FileInputContainer
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      drag={drag}
    >
      {props.children}
    </FileInputContainer>
  );
}
